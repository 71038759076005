<template>
    <div v-if="data_input.i_time == '1800ws'" style="font-size: 0.9rem;">
        <b-row class="mt-n1">
            <b-col md="6" lg="4" class="mb-2">
                <b-card class="small h-100">
                    <h6 class="mb-2 small text-dark font-weight-bolder badge badge-light-dark">PSYCHROMETER SANGKAR</h6>
                    <b-row class="">
                        <b-col cols="12">
                            <b-row v-if="fdih_status.is_fklim || fdih_status.is_agm1a || fdih_status.is_iklim_mikro" class="">
                                <b-col cols="3"></b-col>
                                <b-col cols="3" class="text-center m-auto pr-1 pl-0">
                                    <div class="label-entry2 bg-light-primary">TBK</div>
                                </b-col>
                                <b-col cols="3" class="text-center m-auto pr-1 pl-0">
                                    <div class="label-entry2 bg-light-primary">TBB</div>
                                </b-col>

                                <b-col cols="3" class="text-center pr-1 pl-0">
                                    <div class="label-entry2 bg-light-primary">RH</div>
                                </b-col>
                            </b-row>

                            <b-row v-if="fdih_status.is_fklim || fdih_status.is_agm1a" class="mt-1">
                                <b-col cols="3" class="">
                                    <div class="label-entry1">1.2 m <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" id="tooltips1" /></div>
                                    <!-- HTML tooltips -->
                                    <b-tooltip target="tooltips1" :triggers="'hover'" placement="top">
                                        <dl class="text-left">
                                            <dt>Tbk</dt>
                                            <dd>- Temperatur dari thermometer bola kering pada ketinggian 1.2 m, dinyatakan dalam persepuluhan derajat Celsius.</dd>
                                            <dt>Tbb</dt>
                                            <dd>- Temperatur dari thermometer bola basah pada ketinggian 1.2 m, dinyatakan dalam persepuluhan derajat Celsius.</dd>
                                            <dt>RH</dt>
                                            <dd>- Lembab Nisbi udara pada ketinggian 1.2 m, dinyatakan dalam persen (%)</dd>
                                        </dl>
                                    </b-tooltip>
                                    <!-- end tooltip -->
                                </b-col>
                                <!-- <b-col cols="3" class=" m-auto pr-1 pl-0">
                  <b-form-input
                    v-on:keydown="onKeyCheck($event)"
                    id="input_tbk_1c2m_1800"
                    :formatter="format_one_decimal"
                    size="sm"
                    class="text-center"
                    :state="validateState($v.tbk_1c2m_1800)"
                    v-model.number="$v.tbk_1c2m_1800.$model"
                    v-on:keyup="checkValidate($event, 'tbk_1c2m_1800')"
                    v-bind:class="{ rangecheck: rc_tbk_1c2m_1800 }"
                  ></b-form-input>
                  <b-tooltip v-if="tbk_1c2m_1800" target="input_tbk_1c2m_1800" triggers="hover">{{ rc_tbk_1c2m_1800_message }}</b-tooltip>
                  <b-tooltip v-if="!tbk_1c2m_1800" :show="validateTooltipState('tbk_1c2m_1800')" :triggers="'hover'" target="input_tbk_1c2m_1800" placement="top" custom-class="validation-tooltip">
                    {{ tooltipMessage("tbk_1c2m_1800") }}
                  </b-tooltip>
                </b-col>
                <b-col cols="3" class=" m-auto pr-1 pl-0">
                  <b-form-input
                    v-on:keydown="onKeyCheck($event)"
                    id="input_tbb_1c2m_1800"
                    :formatter="format_one_decimal"
                    size="sm"
                    class="text-center"
                    :state="validateState($v.tbb_1c2m_1800)"
                    v-model.number="$v.tbb_1c2m_1800.$model"
                  ></b-form-input>
                  <b-tooltip v-if="tbb_1c2m_1800" target="input_tbb_1c2m_1800" triggers="hover">{{ rc_tbb_1c2m_1800_message }}</b-tooltip>
                  <b-tooltip v-if="!tbb_1c2m_1800" :show="validateTooltipState('tbb_1c2m_1800')" :triggers="'hover'" target="input_tbb_1c2m_1800" placement="top" custom-class="validation-tooltip">
                    {{ tooltipMessage("tbb_1c2m_1800") }}
                  </b-tooltip>
                </b-col> -->

                                <b-col cols="3" class="m-auto pr-1 pl-0">
                                    <b-form-input
                                        v-on:keydown="onKeyCheck($event)"
                                        id="input_tbk_1c2m_1800"
                                        :formatter="format_one_decimal"
                                        size="sm"
                                        class="text-center"
                                        v-on:keyup="checkValidate($event, 'tbk_1c2m_1800')"
                                        v-bind:class="{ rangecheck: rc_tbk_1c2m_1800 }"
                                        :state="validateState($v.tbk_1c2m_1800)"
                                        v-model.number="$v.tbk_1c2m_1800.$model"
                                    >
                                    </b-form-input>
                                    <b-tooltip v-if="CC.tbk_1c2m_1800.status" target="input_tbk_1c2m_1800" :triggers="'hover'" placement="top">
                                        {{ CC.tbk_1c2m_1800.message }}
                                    </b-tooltip>
                                    <b-tooltip v-if="rc_tbk_1c2m_1800 && !CC.tbk_1c2m_1800.status" target="input_tbk_1c2m_1800" triggers="hover" placement="topleft">{{ rc_tbk_1c2m_1800_message }}</b-tooltip>
                                    <b-tooltip v-if="!rc_tbk_1c2m_1800 && !CC.tbk_1c2m_1800.status" :show="validateTooltipState('tbk_1c2m_1800')" :triggers="'hover'" target="input_tbk_1c2m_1800" placement="topleft" custom-class="validation-tooltip">
                                        {{ tooltipMessage("tbk_1c2m_1800") }}
                                    </b-tooltip>
                                </b-col>
                                <b-col cols="3" class="m-auto pr-1 pl-0">
                                    <b-form-input
                                        v-on:keydown="onKeyCheck($event)"
                                        id="input_tbb_1c2m_1800"
                                        :formatter="format_one_decimal"
                                        size="sm"
                                        class="text-center"
                                        v-on:keyup="checkValidate($event, 'tbb_1c2m_1800')"
                                        v-bind:class="{ rangecheck: rc_tbb_1c2m_1800 }"
                                        :state="validateState($v.tbb_1c2m_1800)"
                                        v-model.number="$v.tbb_1c2m_1800.$model"
                                    >
                                    </b-form-input>
                                    <b-tooltip v-if="CC.tbb_1c2m_1800.status" target="input_tbb_1c2m_1800" :triggers="'hover'" placement="top">
                                        {{ CC.tbb_1c2m_1800.message }}
                                    </b-tooltip>
                                    <b-tooltip v-if="rc_tbb_1c2m_1800 && !CC.tbb_1c2m_1800.status" target="input_tbb_1c2m_1800" triggers="hover" placement="topleft">{{ rc_tbb_1c2m_1800_message }}</b-tooltip>
                                    <b-tooltip v-if="!rc_tbb_1c2m_1800 && !CC.tbb_1c2m_1800.status" :show="validateTooltipState('tbb_1c2m_1800')" :triggers="'hover'" target="input_tbb_1c2m_1800" placement="topleft" custom-class="validation-tooltip">
                                        {{ tooltipMessage("tbb_1c2m_1800") }}
                                    </b-tooltip>
                                </b-col>

                                <b-col cols="3" class="m-auto pr-1 pl-0">
                                    <b-form-input size="sm" class="bg-light-success text-center" v-model.number="rh_1c2m_1800" disabled></b-form-input>
                                </b-col>
                            </b-row>

                            <b-row v-if="fdih_status.is_iklim_mikro" class="mt-1" sm="1">
                                <b-col cols="3" class="">
                                    <div class="label-entry1">4 m <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" id="tooltips2" /></div>
                                    <!-- HTML tooltips -->
                                    <b-tooltip target="tooltips2" :triggers="'hover'" placement="top">
                                        <dl class="text-left">
                                            <dt>Tbk</dt>
                                            <dd>- Temperatur dari thermometer bola kering pada ketinggian 4 m, dinyatakan dalam persepuluhan derajat Celsius.</dd>
                                            <dt>Tbb</dt>
                                            <dd>- Temperatur dari thermometer bola basah pada ketinggian 4 m, dinyatakan dalam persepuluhan derajat Celsius.</dd>
                                            <dt>RH</dt>
                                            <dd>- Lembab Nisbi udara pada ketinggian 4 m, dinyatakan dalam persen (%)</dd>
                                        </dl>
                                    </b-tooltip>
                                </b-col>
                                <b-col cols="3" class="m-auto pr-1 pl-0">
                                    <b-form-input
                                        v-on:keydown="onKeyCheck($event)"
                                        id="input_tbk_4m_1800"
                                        :formatter="format_one_decimal"
                                        size="sm"
                                        class="text-center"
                                        v-on:keyup="checkValidate($event, 'tbk_4m_1800')"
                                        v-bind:class="{ rangecheck: rc_tbk_4m_1800 }"
                                        :state="validateState($v.tbk_4m_1800)"
                                        v-model.number="$v.tbk_4m_1800.$model"
                                    >
                                    </b-form-input>
                                    <b-tooltip v-if="CC.tbk_4m_1800.status" target="input_tbk_4m_1800" :triggers="'hover'" placement="top">
                                        {{ CC.tbk_4m_1800.message }}
                                    </b-tooltip>
                                    <b-tooltip v-if="rc_tbk_4m_1800" target="input_tbk_4m_1800" triggers="hover" placement="topleft">{{ rc_tbk_4m_1800_message }}</b-tooltip>
                                    <b-tooltip v-if="!rc_tbk_4m_1800" :show="validateTooltipState('tbk_4m_1800')" :triggers="'hover'" target="input_tbk_4m_1800" placement="topleft" custom-class="validation-tooltip">
                                        {{ tooltipMessage("tbk_4m_1800") }}
                                    </b-tooltip>
                                </b-col>
                                <b-col cols="3" class="m-auto pr-1 pl-0">
                                    <b-form-input
                                        v-on:keydown="onKeyCheck($event)"
                                        id="tbb_4m_1800"
                                        :formatter="format_one_decimal"
                                        size="sm"
                                        class="text-center"
                                        :state="validateState($v.tbb_4m_1800)"
                                        v-model.number="$v.tbb_4m_1800.$model"
                                    ></b-form-input>
                                    <b-tooltip v-if="CC.tbb_4m_1800.status" target="tbb_4m_1800" :triggers="'hover'" placement="top">
                                        {{ CC.tbb_4m_1800.message }}
                                    </b-tooltip>
                                    <b-tooltip v-if="!CC.tbb_4m_1800.status" :show="validateTooltipState('tbb_4m_1800')" :triggers="'hover'" target="tbb_4m_1800" placement="topleft" custom-class="validation-tooltip">
                                        {{ tooltipMessage("tbb_4m_1800") }}
                                    </b-tooltip>
                                </b-col>

                                <b-col cols="3" class="m-auto pr-1 pl-0">
                                    <b-form-input size="sm" class="bg-light-success text-center" v-model.number="rh_4m_1800" disabled></b-form-input>
                                </b-col>
                            </b-row>

                            <b-row v-if="fdih_status.is_iklim_mikro" class="mt-1">
                                <b-col cols="3" class="">
                                    <div class="label-entry1">7 m <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" id="tooltips3" /></div>
                                    <!-- HTML tooltips -->
                                    <b-tooltip target="tooltips3" :triggers="'hover'" placement="top">
                                        <dl class="text-left">
                                            <dt>Tbk</dt>
                                            <dd>- Temperatur dari thermometer bola kering pada ketinggian 7 m, dinyatakan dalam persepuluhan derajat Celsius.</dd>
                                            <dt>Tbb</dt>
                                            <dd>- Temperatur dari thermometer bola basah pada ketinggian 7 m, dinyatakan dalam persepuluhan derajat Celsius.</dd>
                                            <dt>RH</dt>
                                            <dd>- Lembab Nisbi udara pada ketinggian 7 m, dinyatakan dalam persen (%)</dd>
                                        </dl>
                                    </b-tooltip>
                                </b-col>
                                <b-col cols="3" class="m-auto pr-1 pl-0">
                                    <b-form-input
                                        v-on:keydown="onKeyCheck($event)"
                                        id="input_tbk_7m_1800"
                                        :formatter="format_one_decimal"
                                        size="sm"
                                        class="text-center"
                                        v-on:keyup="checkValidate($event, 'tbk_7m_1800')"
                                        v-bind:class="{ rangecheck: rc_tbk_7m_1800 }"
                                        :state="validateState($v.tbk_7m_1800)"
                                        v-model.number="$v.tbk_7m_1800.$model"
                                    >
                                    </b-form-input>
                                    <b-tooltip v-if="CC.tbk_7m_1800.status" target="input_tbk_7m_1800" :triggers="'hover'" placement="top">
                                        {{ CC.tbk_7m_1800.message }}
                                    </b-tooltip>
                                    <b-tooltip v-if="rc_tbk_7m_1800" target="input_tbk_7m_1800" triggers="hover" placement="topleft">{{ rc_tbk_7m_1800_message }}</b-tooltip>
                                    <b-tooltip v-if="!rc_tbk_7m_1800" :show="validateTooltipState('tbk_7m_1800')" :triggers="'hover'" target="input_tbk_7m_1800" placement="topleft" custom-class="validation-tooltip">
                                        {{ tooltipMessage("tbk_7m_1800") }}
                                    </b-tooltip>
                                </b-col>
                                <b-col cols="3" class="m-auto pr-1 pl-0">
                                    <b-form-input
                                        v-on:keydown="onKeyCheck($event)"
                                        id="tbb_7m_1800"
                                        :formatter="format_one_decimal"
                                        size="sm"
                                        class="text-center"
                                        :state="validateState($v.tbb_7m_1800)"
                                        v-model.number="$v.tbb_7m_1800.$model"
                                    ></b-form-input>
                                    <b-tooltip v-if="CC.tbb_7m_1800.status" target="tbb_7m_1800" :triggers="'hover'" placement="top">
                                        {{ CC.tbb_7m_1800.message }}
                                    </b-tooltip>
                                    <b-tooltip v-if="!CC.tbb_7m_1800.status" :show="validateTooltipState('tbb_7m_1800')" :triggers="'hover'" target="tbb_7m_1800" placement="topleft" custom-class="validation-tooltip">
                                        {{ tooltipMessage("tbb_7m_1800") }}
                                    </b-tooltip>
                                </b-col>

                                <b-col cols="3" class="m-auto pr-1 pl-0">
                                    <b-form-input size="sm" class="bg-light-success text-center" v-model.number="rh_7m_1800" disabled></b-form-input>
                                </b-col>
                            </b-row>

                            <b-row v-if="fdih_status.is_iklim_mikro" class="mt-1">
                                <b-col cols="3" class="">
                                    <div class="label-entry1">10 m <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" id="tooltips4" /></div>
                                    <!-- HTML tooltips -->
                                    <b-tooltip target="tooltips4" :triggers="'hover'" placement="top">
                                        <dl class="text-left">
                                            <dt>Tbk</dt>
                                            <dd>- Temperatur dari thermometer bola kering pada ketinggian 10 m, dinyatakan dalam persepuluhan derajat Celsius.</dd>
                                            <dt>Tbb</dt>
                                            <dd>- Temperatur dari thermometer bola basah pada ketinggian 10 m, dinyatakan dalam persepuluhan derajat Celsius.</dd>
                                            <dt>RH</dt>
                                            <dd>- Lembab Nisbi udara pada ketinggian 10 m, dinyatakan dalam persen (%)</dd>
                                        </dl>
                                    </b-tooltip>
                                </b-col>
                                <b-col cols="3" class="m-auto pr-1 pl-0">
                                    <b-form-input
                                        v-on:keydown="onKeyCheck($event)"
                                        id="input_tbk_10m_1800"
                                        :formatter="format_one_decimal"
                                        size="sm"
                                        class="text-center"
                                        :state="validateState($v.tbk_10m_1800)"
                                        v-model.number="$v.tbk_10m_1800.$model"
                                        v-on:keyup="checkValidate($event, 'tbk_10m_1800')"
                                        v-bind:class="{ rangecheck: rc_tbk_10m_1800 }"
                                    ></b-form-input>
                                    <b-tooltip v-if="CC.tbk_10m_1800.status" target="input_tbk_10m_1800" :triggers="'hover'" placement="top">
                                        {{ CC.tbk_10m_1800.message }}
                                    </b-tooltip>
                                    <b-tooltip v-if="rc_tbk_10m_1800 && !CC.tbk_10m_1800.status" target="input_tbk_10m_1800" triggers="hover" placement="topleft">{{ rc_tbk_10m_1800_message }}</b-tooltip>
                                    <b-tooltip v-if="!rc_tbk_10m_1800 && !CC.tbk_10m_1800.status" :show="validateTooltipState('tbk_10m_1800')" :triggers="'hover'" target="input_tbk_10m_1800" placement="topleft" custom-class="validation-tooltip">
                                        {{ tooltipMessage("tbk_10m_1800") }}
                                    </b-tooltip>
                                </b-col>
                                <b-col cols="3" class="m-auto pr-1 pl-0">
                                    <b-form-input
                                        v-on:keydown="onKeyCheck($event)"
                                        id="tbb_10m_1800"
                                        :formatter="format_one_decimal"
                                        size="sm"
                                        class="text-center"
                                        :state="validateState($v.tbb_10m_1800)"
                                        v-model.number="$v.tbb_10m_1800.$model"
                                    ></b-form-input>
                                    <b-tooltip v-if="CC.tbb_10m_1800.status" target="tbb_10m_1800" :triggers="'hover'" placement="top">
                                        {{ CC.tbb_10m_1800.message }}
                                    </b-tooltip>
                                    <b-tooltip v-if="!CC.tbb_10m_1800.status" :show="validateTooltipState('tbb_10m_1800')" :triggers="'hover'" target="tbb_10m_1800" placement="topleft" custom-class="validation-tooltip">
                                        {{ tooltipMessage("tbb_10m_1800") }}
                                    </b-tooltip>
                                </b-col>

                                <b-col cols="3" class="m-auto pr-1 pl-0">
                                    <b-form-input size="sm" class="bg-light-success text-center" v-model.number="rh_10m_1800" disabled></b-form-input>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>

            <b-col md="6" lg="4" class="mb-2">
                <b-card class="small h-100">
                    <h6 class="mb-2 small text-dark font-weight-bolder badge badge-light-dark">ANGIN</h6>
                    <b-row v-if="fdih_status.is_iklim_mikro || fdih_status.is_agm1a" class="">
                        <b-col cols="12">
                            <b-row class="mb-1">
                                <b-col cols="12" class="font-weight-bolder text-primary"> Kecepatan Rata-Rata </b-col>
                            </b-row>

                            <b-row v-if="fdih_status.is_iklim_mikro || fdih_status.is_agm1a" class="">
                                <b-col cols="3"></b-col>
                                <b-col cols="3" class="text-center pad2">
                                    <div class="label-entry2 bg-light-primary small h-100">COUNTER SEBELUM</div>
                                </b-col>
                                <b-col cols="3" class="text-center">
                                    <div class="label-entry2 bg-light-primary small h-100">COUNTER DIBACA</div>
                                </b-col>
                                <b-col cols="3" class="text-center">
                                    <div class="label-entry2 bg-light-primary small h-100">COUNTER RATA</div>
                                </b-col>
                            </b-row>

                            <!-- <b-row class=" my-1">
                <b-col cols="3" class="m-auto">
                  <div class="label-entry1">
                    0,5 m
                  </div>
                </b-col>
                <b-col cols="3">
                  <b-form-input type="number" size="sm" class="input-dark-grey text-center" v-model="counter_sebelum_0c5_1800" readonly></b-form-input>
                </b-col>
                <b-col cols="3">
                  <b-form-input type="number" size="sm" class="text-center" :state="validateState($v.counter_0c5_1800)" v-model="$v.counter_0c5_1800.$model"></b-form-input>
                </b-col>
                <b-col cols="3">
                  <b-form-input type="number" size="sm" class="bg-light-success text-center" v-model="ws_avg_0c5_1800" readonly></b-form-input>
                </b-col>
              </b-row> -->

                            <b-row v-if="fdih_status.is_iklim_mikro" class="my-1">
                                <b-col cols="3" class="m-auto">
                                    <div class="label-entry1">4 m <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" id="tooltips5" /></div>
                                    <!-- HTML tooltips -->
                                    <b-tooltip target="tooltips5" :triggers="'hover'" placement="top">
                                        <dl class="text-left">
                                            <dt>Counter Sebelum</dt>
                                            <dd>- Cup Counter dibaca 4 m 13.00 WS hari ini</dd>
                                            <dt>Counter dibaca</dt>
                                            <dd>- cup counter dibaca 4 m 18.00 WS hari ini</dd>
                                            <dt>Counter Rata</dt>
                                            <dd>
                                                - Kecepatan angin rata-rata, hasil bagi (sampai dua angka dibelakang koma dari selisih pembacaan Cup Counter yang tingginya 4 m antara jam 13.00 WS sampai jam 18.00 WS dibagi 5. Dinyatakan dalam Km/jam.
                                            </dd>
                                        </dl>
                                    </b-tooltip>
                                </b-col>
                                <b-col cols="3">
                                    <b-form-input v-on:keydown="onKeyCheck($event)" size="sm" class="text-center" v-model.number="counter_sebelum_4_1800" :disabled="!is_counter_edit"></b-form-input>
                                </b-col>
                                <b-col cols="3">
                                    <b-form-input
                                        v-on:keydown="onKeyCheck($event)"
                                        id="counter_4_1800"
                                        :formatter="format_two_decimal"
                                        size="sm"
                                        class="text-center"
                                        :state="validateState($v.counter_4_1800)"
                                        v-model.number="$v.counter_4_1800.$model"
                                    ></b-form-input>
                                    <!-- toltips CC -->
                                    <b-tooltip v-if="CC.counter_4_1800.status" target="counter_4_1800" :triggers="'hover'" placement="top">
                                        {{ CC.counter_4_1800.message }}
                                    </b-tooltip>
                                    <b-tooltip v-if="!CC.counter_4_1800.status" :show="validateTooltipState('counter_4_1800')" :triggers="'hover'" target="counter_4_1800" placement="topleft" custom-class="validation-tooltip">
                                        {{ tooltipMessage("counter_4_1800") }}
                                    </b-tooltip>
                                </b-col>
                                <b-col cols="3">
                                    <b-form-input size="sm" class="bg-light-success text-center" v-model.number="ws_avg_4_1800" disabled></b-form-input>
                                </b-col>
                            </b-row>

                            <b-row v-if="fdih_status.is_iklim_mikro" class="my-1">
                                <b-col cols="3" class="m-auto">
                                    <div class="label-entry1">7 m <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" id="tooltips6" /></div>
                                    <!-- HTML tooltips -->
                                    <b-tooltip target="tooltips6" :triggers="'hover'" placement="topleft">
                                        <dl class="text-left">
                                            <dt>Counter Sebelum</dt>
                                            <dd>- Cup Counter dibaca 7 m 13.00WS hari ini</dd>
                                            <dt>Counter dibaca</dt>
                                            <dd>- cup counter dibaca 7 m 18.00 ws hari ini</dd>
                                            <dt>Counter Rata</dt>
                                            <dd>
                                                - Kecepatan angin rata-rata, hasil bagi (sampai dua angka dibelakang koma dari selisih pembacaan Cup Counter yang tingginya 7 m antara jam 13.00WS sampai jam 18.00 WS dibagi 5. Dinyatakan dalam Km/jam.
                                            </dd>
                                        </dl>
                                    </b-tooltip>
                                </b-col>
                                <b-col cols="3">
                                    <b-form-input v-on:keydown="onKeyCheck($event)" size="sm" class="text-center" v-model.number="counter_sebelum_7_1800" :disabled="!is_counter_edit"></b-form-input>
                                </b-col>
                                <b-col cols="3">
                                    <b-form-input
                                        v-on:keydown="onKeyCheck($event)"
                                        id="counter_7_1800"
                                        :formatter="format_two_decimal"
                                        size="sm"
                                        class="text-center"
                                        :state="validateState($v.counter_7_1800)"
                                        v-model.number="$v.counter_7_1800.$model"
                                    ></b-form-input>
                                    <!-- toltips CC-->
                                    <b-tooltip v-if="CC.counter_7_1800.status" target="counter_7_1800" :triggers="'hover'" placement="topleft">
                                        {{ CC.counter_7_1800.message }}
                                    </b-tooltip>
                                    <b-tooltip v-if="!CC.counter_7_1800.status" :show="validateTooltipState('counter_7_1800')" :triggers="'hover'" target="counter_7_1800" placement="topleft" custom-class="validation-tooltip">
                                        {{ tooltipMessage("counter_7_1800") }}
                                    </b-tooltip>
                                </b-col>
                                <b-col cols="3">
                                    <b-form-input size="sm" class="bg-light-success text-center" v-model.number="ws_avg_7_1800" disabled></b-form-input>
                                </b-col>
                            </b-row>
                            <b-row v-if="fdih_status.is_agm1a" class="my-1">
                                <b-col cols="3" class="m-auto">
                                    <div class="label-entry1">7 m <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" id="tooltipsA" /></div>
                                    <!-- HTML tooltips -->
                                    <b-tooltip target="tooltipsA" :triggers="'hover'" placement="top">
                                        <dl class="text-left">
                                            <dt>Counter Sebelum</dt>
                                            <dd>- Cup Counter dibaca 7 m 14.00 WS hari ini</dd>
                                            <dt>Counter dibaca</dt>
                                            <dd>- cup counter dibaca 7 m 18.00 ws hari ini</dd>
                                            <dt>Counter Rata</dt>
                                            <dd>
                                                - Kecepatan angin rata-rata, hasil bagi (sampai dua angka dibelakang koma dari selisih pembacaan Cup Counter yang tingginya 7 m antara jam 14.00 WS sampai jam 18.00 WS dibagi 5. Dinyatakan dalam Km/jam.
                                            </dd>
                                        </dl>
                                    </b-tooltip>
                                </b-col>
                                <b-col cols="3">
                                    <b-form-input v-on:keydown="onKeyCheck($event)" size="sm" class="text-center" v-model.number.number="counter_sebelum_7_2_1800" :disabled="!is_counter_edit"></b-form-input>
                                </b-col>

                                <b-col v-if="fdih_status.is_iklim_mikro" cols="3"> </b-col>

                                <b-col v-if="!fdih_status.is_iklim_mikro && fdih_status.is_agm1a" cols="3">
                                    <b-form-input
                                        v-on:keydown="onKeyCheck($event)"
                                        id="counter_7_2_1800"
                                        :formatter="format_two_decimal"
                                        size="sm"
                                        class="text-center"
                                        :state="validateState($v.counter_7_2_1800)"
                                        v-model.number="$v.counter_7_2_1800.$model"
                                    ></b-form-input>
                                    <b-tooltip :show="validateTooltipState('counter_7_2_1800')" :triggers="'hover'" target="counter_7_2_1800" placement="topleft" custom-class="validation-tooltip">
                                        {{ tooltipMessage("counter_7_2_1800") }}
                                    </b-tooltip>
                                </b-col>
                                <b-col cols="3">
                                    <b-form-input size="sm" class="bg-light-success text-center" v-model.number="ws_avg_7_2_1800" disabled></b-form-input>
                                </b-col>
                            </b-row>

                            <b-row v-if="fdih_status.is_iklim_mikro" class="my-1">
                                <b-col cols="3" class="m-auto">
                                    <div class="label-entry1">10 m <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" id="tooltips7" /></div>
                                    <!-- HTML tooltips -->
                                    <b-tooltip target="tooltips7" :triggers="'hover'" placement="top">
                                        <dl class="text-left">
                                            <dt>Counter Sebelum</dt>
                                            <dd>- Cup Counter dibaca 10 m 13.00 WS hari ini</dd>
                                            <dt>Counter dibaca</dt>
                                            <dd>- cup counter dibaca 10 m 18.00 WS hari ini</dd>
                                            <dt>Counter Rata</dt>
                                            <dd>
                                                - Kecepatan angin rata-rata, hasil bagi (sampai dua angka dibelakang koma dari selisih pembacaan Cup Counter yang tingginya 10 m antara jam 13.00 WS sampai jam 18.00 WS dibagi 5. Dinyatakan dalam
                                                Km/jam.
                                            </dd>
                                        </dl>
                                    </b-tooltip>
                                </b-col>
                                <b-col cols="3">
                                    <b-form-input v-on:keydown="onKeyCheck($event)" size="sm" class="text-center" v-model.number="counter_sebelum_10_1800" :disabled="!is_counter_edit"></b-form-input>
                                </b-col>
                                <b-col cols="3">
                                    <b-form-input
                                        v-on:keydown="onKeyCheck($event)"
                                        id="counter_10_1800"
                                        :formatter="format_two_decimal"
                                        size="sm"
                                        class="text-center"
                                        :state="validateState($v.counter_10_1800)"
                                        v-model.number="$v.counter_10_1800.$model"
                                    ></b-form-input>
                                    <!-- tooltip CC -->
                                    <b-tooltip v-if="CC.counter_10_1800.status" target="counter_10_1800" :triggers="'hover'" placement="top">
                                        {{ CC.counter_10_1800.message }}
                                    </b-tooltip>
                                    <b-tooltip v-if="!CC.counter_10_1800.status" :show="validateTooltipState('counter_10_1800')" :triggers="'hover'" target="counter_10_1800" placement="topleft" custom-class="validation-tooltip">
                                        {{ tooltipMessage("counter_10_1800") }}
                                    </b-tooltip>
                                </b-col>
                                <b-col cols="3">
                                    <b-form-input size="sm" class="bg-light-success text-center" v-model.number="ws_avg_10_1800" disabled></b-form-input>
                                </b-col>
                            </b-row>

                            <b-row class="mt-1">
                                <b-col cols="3" />
                                <b-col cols="3">
                                    <feather-icon icon="HelpCircleIcon" size="14" class="bg-primary rounded-circle text-white float-right" v-b-tooltip.hover.html="{ title: fdih_counter_sebelum_1800 }" style="margin: 5px auto;" />
                                    <b-form-checkbox tabindex="-1" class="custom-control-primary float-right" name="check-button" switch v-model="is_counter_edit">
                                        <span class="switch-icon-left">
                                            <feather-icon icon="CheckIcon" />
                                        </span>
                                        <span class="switch-icon-right">
                                            <feather-icon icon="XIcon" />
                                        </span>
                                    </b-form-checkbox>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>

                    <hr v-if="fdih_status.is_agm1a" />

                    <b-row v-if="fdih_status.is_agm1a" class="">
                        <b-col cols="12">
                            <b-row v-if="fdih_status.is_agm1a" class="mb-1">
                                <b-col cols="12" class="font-weight-bolder text-primary"> Arah dan Kecepatan saat Peramatan </b-col>
                            </b-row>

                            <b-row class="my-1">
                                <b-col cols="4"></b-col>
                                <b-col cols="4">
                                    <div class="label-entry2 bg-light-primary">ARAH</div>
                                </b-col>
                                <b-col cols="4">
                                    <div class="label-entry2 bg-light-primary">KECEPATAN</div>
                                </b-col>
                            </b-row>

                            <b-row v-if="fdih_status.is_agm1a" class="my-1">
                                <b-col cols="4" class="m-auto">
                                    <div class="label-entry1">10 m <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" id="tooltips8" /></div>
                                    <!-- HTML tooltips -->
                                    <b-tooltip target="tooltips8" :triggers="'hover'" placement="top">
                                        <dl class="text-left">
                                            <dt>Arah</dt>
                                            <dd>- Arah dari mana datangnya angin ketinggian 10 m</dd>
                                            <dt>Kecepatan</dt>
                                            <dd>- Kecepatan angin ketinggian 10 m dalam meter/detik (m/s)</dd>
                                        </dl>
                                    </b-tooltip>
                                </b-col>
                                <b-col cols="4">
                                    <b-form-input
                                        id="input_wd_1800"
                                        v-on:keydown="onKeyCheck($event)"
                                        size="sm"
                                        class="text-center"
                                        :state="validateState($v.wd_1800)"
                                        v-model.number="$v.wd_1800.$model"
                                        v-on:keypress="isNumber($event)"
                                        v-on:keyup="checkValidate($event, 'wd_1800')"
                                        v-bind:class="{ rangecheck: rc_wd_1800 }"
                                    ></b-form-input>
                                    <b-tooltip v-if="rc_wd_1800" target="input_wd_1800" triggers="hover">{{ rc_wd_1800_message }}</b-tooltip>
                                </b-col>
                                <b-col cols="4">
                                    <b-form-input
                                        v-on:keydown="onKeyCheck($event)"
                                        :formatter="format_one_decimal"
                                        id="input_ws_1800"
                                        size="sm"
                                        class="text-center"
                                        :state="validateState($v.ws_1800)"
                                        v-model.number="$v.ws_1800.$model"
                                        v-on:keyup="checkValidate($event, 'ws_1800')"
                                        v-bind:class="{ rangecheck: rc_ws_1800 }"
                                    ></b-form-input>
                                    <b-tooltip v-if="rc_ws_1800" target="input_ws_1800" triggers="hover">{{ rc_ws_1800_message }}</b-tooltip>
                                    <!-- <b-tooltip v-if="!rc_ws_1800" :show="validateTooltipState('ws_1800')" :triggers="'hover'" target="input_ws_1800" placement="top" custom-class="validation-tooltip">
                    {{ tooltipMessage("ws_1400") }}
                  </b-tooltip> -->
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>

            <b-col md="6" lg="4" class="">
                <div class="h-100">
                    <b-card class="">
                        <h6 class="mb-2 small text-dark font-weight-bolder badge badge-light-dark">SUHU MAXIMUM</h6>
                        <b-row v-if="fdih_status.is_fklim || fdih_status.is_agm1a || fdih_status.is_iklim_mikro" class="">
                            <b-col cols="12">
                                <b-row class="reduce-margin-row">
                                    <b-col cols="4"></b-col>
                                    <b-col v-if="fdih_status.is_fklim || fdih_status.is_iklim_mikro" cols="4" class="text-center pr-1 pl-0">
                                        <div class="label-entry2 bg-light-primary">TMAX</div>
                                    </b-col>
                                    <b-col v-if="fdih_status.is_agm1a" cols="4" class="text-center pr-1 pl-0">
                                        <div class="label-entry2 bg-light-primary">TRESET</div>
                                    </b-col>
                                </b-row>

                                <b-row v-if="fdih_status.is_fklim || fdih_status.is_agm1a" class="mt-1">
                                    <b-col cols="4">
                                        <div class="label-entry1">1.2 m <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" id="tooltips19" /></div>
                                        <!-- HTML tooltips -->
                                        <b-tooltip target="tooltips19" :triggers="'hover'" placement="top">
                                            <dl class="text-left">
                                                <dt>Tmax</dt>
                                                <dd>- Temperatur Maksimum ketinggian 1.2m, dinyatakan dalam persepuluhan derajat Celsius.</dd>
                                                <dt>Treset</dt>
                                                <dd>- Temperatur maksimum hasil reset thermometer 1.2m, dinyatakan dalam persepuluhan derajat Celsius.</dd>
                                                <dt>Counter Rata</dt>
                                            </dl>
                                        </b-tooltip>
                                    </b-col>
                                    <b-col v-if="fdih_status.is_fklim" cols="4 " class="m-auto pr-1 pl-0">
                                        <b-form-input
                                            :formatter="format_one_decimal"
                                            size="sm"
                                            class="ml-0 text-center"
                                            id="input_t_max_1c2m"
                                            v-on:keydown="onKeyCheck($event)"
                                            v-on:keyup="checkValidate($event, 't_max_1c2m')"
                                            v-bind:class="{ rangecheck: rc_t_max_1c2m }"
                                            :state="validateState($v.t_max_1c2m)"
                                            v-model.number="$v.t_max_1c2m.$model"
                                        ></b-form-input>
                                        <b-tooltip v-if="rc_t_max_1c2m" target="input_t_max_1c2m" triggers="hover" placement="topleft">{{ rc_t_max_1c2m_message }}</b-tooltip>
                                        <b-tooltip v-if="!rc_t_max_1c2m" :show="validateTooltipState('t_max_1c2m')" :triggers="'hover'" target="input_t_max_1c2m" placement="topleft" custom-class="validation-tooltip">
                                            {{ tooltipMessage("t_max_1c2m") }}
                                        </b-tooltip>
                                    </b-col>
                                    <!-- <b-col v-if="!fdih_status.is_agm1a"/> -->
                                    <b-col v-if="fdih_status.is_agm1a" cols="4" class="m-auto pr-1 pl-0">
                                        <b-form-input
                                            :formatter="format_one_decimal"
                                            size="sm"
                                            class="text-center"
                                            id="input_t_reset_1c2m_1800"
                                            v-on:keydown="onKeyCheck($event)"
                                            v-on:keyup="checkValidate($event, 't_reset_1c2m_1800')"
                                            v-bind:class="{ rangecheck: rc_t_reset_1c2m_1800 }"
                                            :state="validateState($v.t_reset_1c2m_1800)"
                                            v-model.number="$v.t_reset_1c2m_1800.$model"
                                        ></b-form-input>
                                        <b-tooltip v-if="rc_t_reset_1c2m_1800" target="input_t_reset_1c2m_1800" triggers="hover" placement="topleft">{{ rc_t_reset_1c2m_1800_message }}</b-tooltip>
                                        <b-tooltip v-if="!rc_t_reset_1c2m_1800" :show="validateTooltipState('t_reset_1c2m_1800')" :triggers="'hover'" target="input_t_reset_1c2m_1800" placement="topleft" custom-class="validation-tooltip">
                                            {{ tooltipMessage("t_reset_1c2m_1800") }}
                                        </b-tooltip>
                                    </b-col>
                                    <b-col v-if="fdih_status.is_fklim || fdih_status.is_agm1a" cols="4"> </b-col>
                                </b-row>

                                <b-row v-if="fdih_status.is_iklim_mikro" class="mt-1">
                                    <b-col cols="4" class="m-auto">
                                        <div class="label-entry1">
                                            4 m
                                            <feather-icon
                                                icon="HelpCircleIcon"
                                                size="12"
                                                class="bg-primary rounded-circle text-white"
                                                v-b-tooltip.hover.v-dark
                                                title="Temperatur Maksimum ketinggian 4m, dinyatakan dalam persepuluhan derajat Celsius."
                                            />
                                        </div>
                                    </b-col>
                                    <b-col cols="4 " class="m-auto pr-1 pl-0">
                                        <b-form-input
                                            :formatter="format_one_decimal"
                                            size="sm"
                                            class="text-center"
                                            id="input_t_max_4m"
                                            v-on:keydown="onKeyCheck($event)"
                                            v-on:keyup="checkValidate($event, 't_max_4m')"
                                            v-bind:class="{ rangecheck: rc_t_max_4m }"
                                            :state="validateState($v.t_max_4m)"
                                            v-model.number="$v.t_max_4m.$model"
                                        ></b-form-input>
                                        <b-tooltip v-if="rc_t_max_4m" target="input_t_max_4m" triggers="hover" placement="topleft">{{ rc_t_max_4m_message }}</b-tooltip>
                                        <b-tooltip v-if="!rc_t_max_4m" :show="validateTooltipState('t_max_4m')" :triggers="'hover'" target="input_t_max_4m" placement="topleft" custom-class="validation-tooltip">
                                            {{ tooltipMessage("t_max_4m") }}
                                        </b-tooltip>
                                    </b-col>
                                    <b-col cols="4"> </b-col>
                                </b-row>

                                <b-row v-if="fdih_status.is_iklim_mikro" class="mt-1">
                                    <b-col cols="4" class="m-auto">
                                        <div class="label-entry1">
                                            7 m
                                            <feather-icon
                                                icon="HelpCircleIcon"
                                                size="12"
                                                class="bg-primary rounded-circle text-white"
                                                v-b-tooltip.hover.v-dark
                                                title="Temperatur Maksimum ketinggian 7m, dinyatakan dalam persepuluhan derajat Celsius."
                                            />
                                        </div>
                                    </b-col>
                                    <b-col cols="4 " class="m-auto pr-1 pl-0">
                                        <b-form-input
                                            :formatter="format_one_decimal"
                                            size="sm"
                                            class="text-center"
                                            id="input_t_max_7m"
                                            v-on:keydown="onKeyCheck($event)"
                                            v-on:keyup="checkValidate($event, 't_max_7m')"
                                            v-bind:class="{ rangecheck: rc_t_max_7m }"
                                            :state="validateState($v.t_max_7m)"
                                            v-model.number="$v.t_max_7m.$model"
                                        ></b-form-input>
                                        <b-tooltip v-if="rc_t_max_7m" target="input_t_max_7m" triggers="hover" placement="topleft">{{ rc_t_max_7m_message }}</b-tooltip>
                                        <b-tooltip v-if="!rc_t_max_7m" :show="validateTooltipState('t_max_7m')" :triggers="'hover'" target="input_t_max_7m" placement="topleft" custom-class="validation-tooltip">
                                            {{ tooltipMessage("t_max_7m") }}
                                        </b-tooltip>
                                    </b-col>
                                    <b-col cols="4"> </b-col>
                                </b-row>

                                <b-row v-if="fdih_status.is_iklim_mikro" class="mt-1">
                                    <b-col cols="4">
                                        <div class="label-entry1">
                                            10 m
                                            <feather-icon
                                                icon="HelpCircleIcon"
                                                size="12"
                                                class="bg-primary rounded-circle text-white"
                                                v-b-tooltip.hover.v-dark
                                                title="Temperatur Maksimum ketinggian 10m, dinyatakan dalam persepuluhan derajat Celsius."
                                            />
                                        </div>
                                    </b-col>
                                    <b-col cols="4 " class="m-auto pr-1 pl-0">
                                        <b-form-input
                                            :formatter="format_one_decimal"
                                            size="sm"
                                            class="text-center"
                                            id="input_t_max_10m"
                                            v-on:keydown="onKeyCheck($event)"
                                            v-on:keyup="checkValidate($event, 't_max_10m')"
                                            v-bind:class="{ rangecheck: rc_t_max_10m }"
                                            :state="validateState($v.t_max_10m)"
                                            v-model.number="$v.t_max_10m.$model"
                                        ></b-form-input>
                                        <b-tooltip v-if="rc_t_max_10m" target="input_t_max_10m" triggers="hover" placement="topleft">{{ rc_t_max_10m_message }}</b-tooltip>
                                        <b-tooltip v-if="!rc_t_max_10m" :show="validateTooltipState('t_max_10m')" :triggers="'hover'" target="input_t_max_10m" placement="topleft" custom-class="validation-tooltip">
                                            {{ tooltipMessage("t_max_10m") }}
                                        </b-tooltip>
                                    </b-col>
                                    <b-col cols="4"> </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                    </b-card>

                    <b-card class="">
                        <span>
                            <h6 class="mb-1 small text-dark font-weight-bolder badge badge-light-dark">TEKANAN UDARA QFE</h6>
                            <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Tekanan udara QFE dari Synop jam 00 UTC" />
                        </span>

                        <b-row v-if="fdih_status.is_fklim">
                            <b-col cols="8 mb-2">
                                <b-form-input
                                    v-on:keydown="onKeyCheck($event)"
                                    :formatter="format_one_decimal"
                                    id="input_pp_qfe_0000"
                                    size="sm"
                                    class="text-center"
                                    :state="validateState($v.pp_qfe_0000)"
                                    v-model.number="$v.pp_qfe_0000.$model"
                                    :disabled="!is_edit_pp_qfe_0000"
                                ></b-form-input>
                                <b-tooltip :show="validateTooltipState('pp_qfe_0000')" :triggers="'hover'" target="input_pp_qfe_0000" placement="topleft" custom-class="validation-tooltip">
                                    {{ tooltipMessage("pp_qfe_0000") }}
                                </b-tooltip>
                            </b-col>
                            <b-col sm="3">
                                <b-form-checkbox tabindex="-1" class="custom-control-primary" name="check-button" switch v-model="is_edit_pp_qfe_0000">
                                    <span class="switch-icon-left">
                                        <feather-icon icon="CheckIcon" />
                                    </span>
                                    <span class="switch-icon-right">
                                        <feather-icon icon="XIcon" />
                                    </span>
                                </b-form-checkbox>
                                <feather-icon icon="HelpCircleIcon" size="14" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Edit manual" />
                            </b-col>
                        </b-row>
                    </b-card>
                </div>
            </b-col>
        </b-row>

        <!-- button Action-->
        <b-row>
            <b-col cols="12">
                <b-card>
                    <b-row class="float-right">
                        <div>
                            <b-button @click.prevent="cancelForm" variant="danger" class="mr-2" size="sm">Cancel</b-button>
                            <!-- <b-button v-if="this.t_data_status == 'update'" @click.prevent="validationForm" variant="warning" class="mr-2" size="sm" :disabled="!roles.isRoleEdit">Edit</b-button>
              <b-button v-if="this.t_data_status == 'new'" @click.prevent="validationForm" variant="primary" class="mr-2" size="sm" :disabled="!roles.isRoleAdd">Submit</b-button> -->
                            <b-button @click.prevent="validationForm" variant="primary" class="mr-2" size="sm">Submit</b-button>
                        </div>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-overlay :show="showLoading" rounded="sm" no-wrap> </b-overlay>
    </div>
</template>
<script>
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import Service from "@/api/fdihservice";
import axios from "axios";
import qc_helper from "@/api/qc_helper";
import Helper from "@/helper/helper";
import Ripple from "vue-ripple-directive";
import Params from "@/attr/metadata/params";
import RC_helper from "@/validators/RC_helper";
import { BCard, BCardHeader, BContainer, BRow, BCol, BFormSelect, BForm, BButton, BFormGroup, BCollapse, BFormDatepicker, BFormInput, VBTooltip, BTooltip, BIcon, BFormCheckbox } from "bootstrap-vue";
// } from '@validations'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { fdih_counter_sebelum_1800 } from "./fdih_tooltip_content_1800";
import { GC_FDIH_PS_1800_TBK, GC_FDIH_PS_1800_TBB, GC_FDIH_1800_ANGIN_COUNTER_BACA, GC_FDIH_SM_1800_Tmax, GC_FDIH_SM_1800_Treset, RC_FDIH_ROLES, RC_FDIH, GC_FDIH, GC_FDIH_1800_QFE } from "@/validators/fdihvalidator";
export default {
    components: {
        BCardActions,
        BCard,
        BCardHeader,
        BContainer,
        BRow,
        BCol,
        BFormSelect,
        BForm,
        BButton,
        BFormGroup,
        BCollapse,
        BFormDatepicker,
        BFormInput,
        VBTooltip,
        BTooltip,
        BIcon,
        BFormCheckbox,
    },
    props: ["data_input", "t_station", "t_station_id", "t_wmoid", "t_observer", "t_time", "t_date", "t_data_existing", "t_all_data", "t_data_requirement", "t_path", "t_id_form", "t_data_status", "roles", "t_bmkgentry", "d_station", "t_qc_histories"],
    data() {
        return {
            showLoading: false,
            RC_helper_agm1a: { ...RC_helper },
            RC_helper_imikro: { ...RC_helper },
            RC_helper_fklim: { ...RC_helper },
            flagdata_fklim: null,
            flagdata_iklim_mikro: null,
            flagdata_agm1a: null,
            tbk_1c2m_1800: "",
            tbk_4m_1800: "",
            tbk_7m_1800: "",
            tbk_10m_1800: "",
            tbb_1c2m_1800: "",
            tbb_4m_1800: "",
            tbb_7m_1800: "",
            tbb_10m_1800: "",
            rh_1c2m_1800: "",
            rh_4m_1800: "",
            rh_7m_1800: "",
            rh_10m_1800: "",
            ws_avg_0c5_1800: "",
            // counter_0c5_1800: "",
            counter_sebelum_0c5_1800: "",
            ws_avg_4_1800: "",
            counter_4_1800: "",
            counter_sebelum_4_1800: "",
            ws_avg_7_1800: "",
            ws_avg_7_2_1800: "",
            counter_7_1800: "",
            counter_7_2_1800: "",
            counter_sebelum_7_1800: "",
            counter_sebelum_7_2_1800: "",
            ws_avg_10_1800: "",
            counter_10_1800: "",
            counter_sebelum_10_1800: "",
            t_max_1c2m: "",
            t_max_4m: "",
            t_max_7m: "",
            t_max_10m: "",
            t_reset_1c2m_1800: "",
            wd_1800: "",
            ws_1800: "",
            pp_qfe_0000: "",
            CC: {
                tbk_1c2m_1800: { status: false, message: "" },
                tbk_4m_1800: { status: false, message: "" },
                tbk_7m_1800: { status: false, message: "" },
                tbk_10m_1800: { status: false, message: "" },
                tbb_1c2m_1800: { status: false, message: "" },
                tbb_4m_1800: { status: false, message: "" },
                tbb_7m_1800: { status: false, message: "" },
                tbb_10m_1800: { status: false, message: "" },
                counter_4_1800: { status: false, message: "" },
                counter_7_1800: { status: false, message: "" },
                counter_7_2_1800: { status: false, message: "" },
                counter_10_1800: { status: false, message: "" },
            },

            //rc
            rc_t_max_1c2m: false,
            rc_t_max_1c2m_message: "",
            rc_t_max_4m: false,
            rc_t_max_4m_message: "",
            rc_t_max_7m: false,
            rc_t_max_7m_message: "",
            rc_t_max_10m: false,
            rc_t_max_10m_message: "",
            rc_t_reset_1c2m_1800: false,
            rc_t_reset_1c2m_1800_message: "",
            rc_ws_1800: false,
            rc_ws_1800_message: "",
            rc_wd_1800: false,
            rc_wd_1800_message: "",
            rc_tbk_1c2m_1800: false,
            rc_tbk_1c2m_1800_message: "",
            rc_tbb_1c2m_1800: false,
            rc_tbb_1c2m_1800_message: "",
            rc_tbk_4m_1800: false,
            rc_tbk_4m_1800_message: "",
            rc_tbk_7m_1800: false,
            rc_tbk_7m_1800_message: "",
            rc_tbk_10m_1800: false,
            rc_tbk_10m_1800_message: "",

            fdih_status: {
                is_fklim: true,
                is_iklim_mikro: true,
                is_agm1a: true,
                is_agm1b: true,
                is_suhu_tanah: true,
                is_psychrometer_assman: true,
                is_op_penguapan: true,
                is_piche_penguapan: true,
                is_lysimeter: true,
                is_gunbellani: true,
            },

            is_edit_pp_qfe_0000: false,

            is_counter_edit: false,

            fdih_counter_sebelum_1800,

            DATA_RC: {},
            LIST_QC_FIX: {},
            LIST_QC_NOTIFICATION_FIX: {},

            agm1a_histories_last: null,
            iklimmikro_histories_last: null,
        };
    },
    mixins: [validationMixin],
    validations: {
        tbk_1c2m_1800: {
            required,
            GC_FDIH_PS_1800_TBK,
            consistency(val) {
                if (this.tbb_1c2m_1800 && this.tbb_1c2m_1800 != 9999 && val != 9999) {
                    let result = val >= this.tbb_1c2m_1800;
                    this.CC.tbk_1c2m_1800.message = "Suhu Bola Kering >= Suhu Bola Basah";
                    this.CC.tbk_1c2m_1800.status = !result;
                    return result;
                }
                this.CC.tbk_1c2m_1800.message = "";
                this.CC.tbk_1c2m_1800.status = false;
                return true;
            },
        },
        tbk_4m_1800: {
            required,
            GC_FDIH_PS_1800_TBK,
            consistency(val) {
                if (this.tbb_4m_1800 && this.tbb_4m_1800 != 9999 && val != 9999) {
                    let result = val >= this.tbb_4m_1800;
                    this.CC.tbk_4m_1800.message = "Suhu Bola Kering >= Suhu Bola Basah";
                    this.CC.tbk_4m_1800.status = !result;
                    return result;
                }
                this.CC.tbk_4m_1800.message = "";
                this.CC.tbk_4m_1800.status = false;
                return true;
            },
        },
        tbk_7m_1800: {
            required,
            GC_FDIH_PS_1800_TBK,
            consistency(val) {
                if (this.tbb_7m_1800 && this.tbb_7m_1800 != 9999 && val != 9999) {
                    let result = val >= this.tbb_7m_1800;
                    this.CC.tbk_7m_1800.message = "Suhu Bola Kering >= Suhu Bola Basah";
                    this.CC.tbk_7m_1800.status = !result;
                    return result;
                }
                this.CC.tbk_7m_1800.message = "";
                this.CC.tbk_7m_1800.status = false;
                return true;
            },
        },
        tbk_10m_1800: {
            required,
            GC_FDIH_PS_1800_TBK,
            consistency(val) {
                if (this.tbb_10m_1800 && this.tbb_10m_1800 != 9999 && val != 9999) {
                    let result = val >= this.tbb_10m_1800;
                    this.CC.tbk_10m_1800.message = "Suhu Bola Kering >= Suhu Bola Basah";
                    this.CC.tbk_10m_1800.status = !result;
                    return result;
                }
                this.CC.tbk_10m_1800.message = "";
                this.CC.tbk_10m_1800.status = false;
                return true;
            },
        },

        tbb_1c2m_1800: {
            required,
            GC_FDIH_PS_1800_TBB,
            consistency(val) {
                if (this.tbk_1c2m_1800 && this.tbk_1c2m_1800 != 9999 && val != 9999) {
                    let result = val <= this.tbk_1c2m_1800;
                    this.CC.tbb_1c2m_1800.message = "Suhu Bola Kering >= Suhu Bola Basah";
                    this.CC.tbb_1c2m_1800.status = !result;
                    return result;
                }
                this.CC.tbb_1c2m_1800.message = "";
                this.CC.tbb_1c2m_1800.status = false;
                return true;
            },
        },
        tbb_4m_1800: {
            required,
            GC_FDIH_PS_1800_TBB,
            consistency(val) {
                if (this.tbk_4m_1800 && this.tbk_4m_1800 != 9999 && val != 9999) {
                    let result = val <= this.tbk_4m_1800;
                    this.CC.tbb_4m_1800.message = "Suhu Bola Kering >= Suhu Bola Basah";
                    this.CC.tbb_4m_1800.status = !result;
                    return result;
                }
                this.CC.tbb_4m_1800.message = "";
                this.CC.tbb_4m_1800.status = false;
                return true;
            },
        },
        tbb_7m_1800: {
            required,
            GC_FDIH_PS_1800_TBB,
            consistency(val) {
                if (this.tbk_7m_1800 && this.tbk_7m_1800 != 9999 && val != 9999) {
                    let result = val <= this.tbk_7m_1800;
                    this.CC.tbb_7m_1800.message = "Suhu Bola Kering >= Suhu Bola Basah";
                    this.CC.tbb_7m_1800.status = !result;
                    return result;
                }
                this.CC.tbb_7m_1800.message = "";
                this.CC.tbb_7m_1800.status = false;
                return true;
            },
        },
        tbb_10m_1800: {
            required,
            GC_FDIH_PS_1800_TBB,
            consistency(val) {
                if (this.tbk_10m_1800 && this.tbk_10m_1800 != 9999 && val != 9999) {
                    let result = val <= this.tbk_10m_1800;
                    this.CC.tbb_10m_1800.message = "Suhu Bola Kering >= Suhu Bola Basah";
                    this.CC.tbb_10m_1800.status = !result;
                    return result;
                }
                this.CC.tbb_10m_1800.message = "";
                this.CC.tbb_10m_1800.status = false;
                return true;
            },
        },
        //Consitensi Cek
        // counter_0c5_1800: { required, GC_FDIH_1800_ANGIN_COUNTER_BACA },
        counter_4_1800: {
            required,
            GC_FDIH_1800_ANGIN_COUNTER_BACA,
            consistency(val) {
                if (this.counter_sebelum_4_1800 && this.counter_sebelum_4_1800 != 9999 && val != 9999) {
                    let result = val >= this.counter_sebelum_4_1800;
                    this.CC.counter_4_1800.message = "Counter Dibaca >= Counter Sebelum";
                    this.CC.counter_4_1800.status = !result;
                    return result;
                }
                this.CC.counter_4_1800.message = "";
                this.CC.counter_4_1800.status = false;
                return true;
            },
        },
        counter_7_1800: {
            required,
            GC_FDIH_1800_ANGIN_COUNTER_BACA,
            consistency(val) {
                if (this.counter_sebelum_7_1800 && this.counter_sebelum_7_1800 != 9999 && val != 9999) {
                    let result = val >= this.counter_sebelum_7_1800;
                    this.CC.counter_7_1800.message = "Counter Dibaca >= Counter Sebelum";
                    this.CC.counter_7_1800.status = !result;
                    return result;
                }
                this.CC.counter_7_1800.message = "";
                this.CC.counter_7_1800.status = false;
                return true;
            },
        },
        counter_7_2_1800: {
            required,
            GC_FDIH_1800_ANGIN_COUNTER_BACA,
            consistency(val) {
                if (this.counter_sebelum_7_2_1800 && this.counter_sebelum_7_2_1800 != 9999 && val != 9999) {
                    let result = val >= this.counter_sebelum_7_2_1800;
                    this.CC.counter_7_2_1800.message = "Counter Dibaca >= Counter Sebelum";
                    this.CC.counter_7_2_1800.status = !result;
                    return result;
                }
                this.CC.counter_7_2_1800.message = "";
                this.CC.counter_7_2_1800.status = false;
                return true;
            },
        },
        counter_10_1800: {
            required,
            GC_FDIH_1800_ANGIN_COUNTER_BACA,
            consistency(val) {
                if (this.counter_sebelum_10_1800 && this.counter_sebelum_10_1800 != 9999 && val != 9999) {
                    let result = val >= this.counter_sebelum_10_1800;
                    this.CC.counter_10_1800.message = "Counter Dibaca >= Counter Sebelum";
                    this.CC.counter_10_1800.status = !result;
                    return result;
                }
                this.CC.counter_10_1800.message = "";
                this.CC.counter_10_1800.status = false;
                return true;
            },
        },

        t_max_1c2m: { required, GC_FDIH_SM_1800_Tmax },
        t_max_4m: { required, GC_FDIH_SM_1800_Tmax },
        t_max_7m: { required, GC_FDIH_SM_1800_Tmax },
        t_max_10m: { required, GC_FDIH_SM_1800_Tmax },

        t_reset_1c2m_1800: { required, GC_FDIH_SM_1800_Treset },

        wd_1800: { required },
        ws_1800: { required },

        pp_qfe_0000: { required, GC_FDIH_1800_QFE },
    },

    mounted() {
        this.initialize();
        this.getQCNotification();
    },

    methods: {
        initialize() {
            this.is_edit_pp_qfe_0000 = false;
            this.setQCData();
            if (this.t_bmkgentry) {
                (this.fdih_status.is_fklim = this.t_bmkgentry.is_fklim),
                    (this.fdih_status.is_iklim_mikro = this.t_bmkgentry.is_iklim_mikro),
                    (this.fdih_status.is_agm1a = this.t_bmkgentry.is_agm1a),
                    (this.fdih_status.is_agm1b = this.t_bmkgentry.is_agm1b),
                    (this.fdih_status.is_suhu_tanah = this.t_bmkgentry.is_suhu_tanah),
                    (this.fdih_status.is_psychrometer_assman = this.t_bmkgentry.is_psychrometer_assman),
                    (this.fdih_status.is_op_penguapan = this.t_bmkgentry.is_op_penguapan),
                    (this.fdih_status.is_piche_penguapan = this.t_bmkgentry.is_piche_penguapan),
                    (this.fdih_status.is_lysimeter = this.t_bmkgentry.is_lysimeter),
                    (this.fdih_status.is_gunbellani = this.t_bmkgentry.is_gunbellani);
            }
        },

        getQCNotification() {},

        async setQCData() {
            if (this.d_station) {
                let qc_data_agm1a = this.d_station["BmkgSatu.metadata.qc.IAgm1aqc"];
                let qc_params_agm1a = Params.GET_PARAMS("agm1a");
                let qc_key_agm1a = Params.GET_KEY("agm1a");
                this.RC_helper_agm1a.setData(qc_data_agm1a, qc_params_agm1a, qc_key_agm1a);

                let qc_data_imikro = this.d_station["BmkgSatu.metadata.qc.IIklimMikroqc"];
                let qc_params_imikro = Params.GET_PARAMS("iklim_mikro");
                let qc_key_imikro = Params.GET_KEY("iklim_mikro");
                this.RC_helper_imikro.setData(qc_data_imikro, qc_params_imikro, qc_key_imikro);

                let qc_data_fklim = this.d_station["BmkgSatu.metadata.qc.IFklim71qc"];
                let qc_params_fklim = Params.GET_PARAMS("fklim");
                let qc_key_fklim = Params.GET_KEY("fklim");
                this.RC_helper_fklim.setData(qc_data_fklim, qc_params_fklim, qc_key_fklim);
            }
        },

        onKeyCheck(event) {
            if (event.key === "ArrowUp" || event.key === "ArrowDown") {
                event.preventDefault();
            }
        },

        //RC 18:00
        checkValidate: function (event, type) {
            // console.log("event", event);
            let value = event.target.value;

            switch (type) {
                case "t_max_1c2m":
                    var raw = this.RC_helper_agm1a.check(value, "t_max_1c2m"); //RC_FDIH(value, "t_max_1c2m");
                    var gc = GC_FDIH_SM_1800_Tmax(value);
                    this.rc_t_max_1c2m = !raw.status ? (!gc ? false : true) : false;
                    this.rc_t_max_1c2m_message = raw.message;
                    this.setDataRC(type, this.rc_t_max_1c2m, this.rc_t_max_1c2m_message, raw.roles);
                    break;
                case "t_max_4m":
                    var raw = this.RC_helper_imikro.check(value, "t_max_4m"); //RC_FDIH(value, "t_max_4m");
                    var gc = GC_FDIH_SM_1800_Tmax(value);
                    this.rc_t_max_4m = !raw.status ? (!gc ? false : true) : false;
                    this.rc_t_max_4m_message = raw.message;
                    this.setDataRC(type, this.rc_t_max_4m, this.rc_t_max_4m_message, raw.roles);
                    break;
                case "t_max_7m":
                    var raw = this.RC_helper_imikro.check(value, "t_max_7m"); //RC_FDIH(value, "t_max_7m");
                    var gc = GC_FDIH_SM_1800_Tmax(value);
                    this.rc_t_max_7m = !raw.status ? (!gc ? false : true) : false;
                    this.rc_t_max_7m_message = raw.message;
                    this.setDataRC(type, this.rc_t_max_7m, this.rc_t_max_7m_message, raw.roles);
                    break;
                case "t_max_10m":
                    var raw = this.RC_helper_imikro.check(value, "t_max_10m"); //RC_FDIH(value, "t_max_10m");
                    var gc = GC_FDIH_SM_1800_Tmax(value);
                    this.rc_t_max_10m = !raw.status ? (!gc ? false : true) : false;
                    this.rc_t_max_10m_message = raw.message;
                    this.setDataRC(type, this.rc_t_max_10m, this.rc_t_max_10m_message, raw.roles);
                    break;
                case "t_reset_1c2m_1800":
                    var raw = this.RC_helper_agm1a.check(value, "t_reset_1c2m_1800"); //RC_FDIH(value, "t_reset_1c2m_1800");
                    var gc = GC_FDIH_SM_1800_Treset(value);
                    this.rc_t_reset_1c2m_1800 = !raw.status ? (!gc ? false : true) : false;
                    this.rc_t_reset_1c2m_1800_message = raw.message;
                    this.setDataRC(type, this.rc_t_reset_1c2m_1800, this.rc_t_reset_1c2m_1800_message, raw.roles);
                    break;
                case "tbk_1c2m_1800":
                    var raw = this.RC_helper_agm1a.check(value, "tbk_1c2m_1800");
                    var gc = GC_FDIH_PS_1800_TBK(value);
                    this.rc_tbk_1c2m_1800 = !raw.status ? (!gc ? false : true) : false;
                    this.rc_tbk_1c2m_1800_message = raw.message;
                    this.setDataRC(type, this.rc_tbk_1c2m_1800, this.rc_tbk_1c2m_1800_message, raw.roles);
                    break;
                case "tbb_1c2m_1800":
                    var raw = this.RC_helper_agm1a.check(value, "tbb_1c2m_1800");
                    var gc = GC_FDIH_PS_1800_TBB(value);
                    this.rc_tbb_1c2m_1800 = !raw.status ? (!gc ? false : true) : false;
                    this.rc_tbb_1c2m_1800_message = raw.message;
                    this.setDataRC(type, this.rc_tbb_1c2m_1800, this.rc_tbb_1c2m_1800_message, raw.roles);
                    break;
                case "tbk_4m_1800":
                    var raw = this.RC_helper_imikro.check(value, "tbk_4m_1800");
                    var gc = GC_FDIH_PS_1800_TBK(value);
                    this.rc_tbk_4m_1800 = !raw.status ? (!gc ? false : true) : false;
                    this.rc_tbk_4m_1800_message = raw.message;
                    this.setDataRC(type, this.rc_tbk_4m_1800, this.rc_tbk_4m_1800_message, raw.roles);
                    break;
                case "tbk_7m_1800":
                    var raw = this.RC_helper_imikro.check(value, "tbk_7m_1800");
                    var gc = GC_FDIH_PS_1800_TBK(value);
                    this.rc_tbk_7m_1800 = !raw.status ? (!gc ? false : true) : false;
                    this.rc_tbk_7m_1800_message = raw.message;
                    this.setDataRC(type, this.rc_tbk_7m_1800, this.rc_tbk_7m_1800_message, raw.roles);
                    break;
                case "tbk_10m_1800":
                    var raw = this.RC_helper_imikro.check(value, "tbk_10m_1800");
                    var gc = GC_FDIH_PS_1800_TBK(value);
                    this.rc_tbk_10m_1800 = !raw.status ? (!gc ? false : true) : false;
                    this.rc_tbk_10m_1800_message = raw.message;
                    this.setDataRC(type, this.rc_tbk_10m_1800, this.rc_tbk_10m_1800_message, raw.roles);
                    break;
                case "ws_1800":
                    var raw = this.RC_helper_agm1a.check(value, "ws_1800"); //RC_FDIH(value, "ws_0700");
                    var gc = true; //GC_FDIH_1800_ANGIN_KECEPATAN(value);
                    this.rc_ws_1800 = !raw.status ? (!gc ? false : true) : false;
                    this.rc_ws_1800_message = raw.message;
                    this.setDataRC(type, this.rc_ws_1800, this.rc_ws_1800_message, raw.roles);
                    break;
                case "wd_1800":
                    var raw = this.RC_helper_agm1a.check(value, "wd_1800"); //RC_FDIH(value, "ws_0700");
                    var gc = true; //GC_FDIH_1800_ANGIN_ARAH(value);
                    this.rc_wd_1800 = !raw.status ? (!gc ? false : true) : false;
                    this.rc_wd_1800_message = raw.message;
                    this.setDataRC(type, this.rc_wd_1800, this.rc_wd_1800_message, raw.roles);
                    break;
                default:
                    break;
            }
        },

        setDataRC(type, status, message, roles) {
            if (status) {
                this.DATA_RC[type] = {
                    status: status,
                    message: message,
                    roles: roles,
                };
            } else {
                if (this.DATA_RC.hasOwnProperty(type)) {
                    delete this.DATA_RC[type];
                }
            }
        },

        tooltipMessage(attr) {
            // console.log("tooltipMessage", attr);
            return GC_FDIH(attr).message;
        },
        validateTooltipState(name) {
            const { $error } = this.$v[name];
            return false; //$error;
        },

        format_one_decimal(e) {
            if (Math.round(e * 10) / 10 != e) {
                let text = String(e);
                let filter = text.substring(0, text.length - 1);
                return filter;
            } else {
                if (Math.floor(e) === e) {
                    return e;
                } else {
                    if (e.toString().indexOf(".") > 0) {
                        let count = e.toString().split(".")[1].length || 0;
                        return count > 1 ? parseFloat(e).toFixed(1) : e;
                    } else {
                        return e;
                    }
                }
                return e;
            }
        },
        format_two_decimal(e, event) {
            if (Math.round(e * 100) / 100 != e) {
                let text = String(e);
                let filter = text.substring(0, text.length - 1);
                return filter;
            } else {
                if (Math.floor(e) === e) {
                    return e;
                } else {
                    if (e.toString().indexOf(".") > 0) {
                        let count = e.toString().split(".")[1].length || 0;
                        return count > 2 ? parseFloat(e).toFixed(2) : e;
                    } else {
                        return e;
                    }
                }

                return e;
            }
        },
        isNumber(evt) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
                evt.preventDefault();
            }
            if (evt.key == "." || evt.key == ",") {
                return evt.preventDefault();
            } else {
                return true;
            }
        },
        clearData() {
            this.tbk_1c2m_1800 = "";
            this.tbk_4m_1800 = "";
            this.tbk_7m_1800 = "";
            this.tbk_10m_1800 = "";
            this.tbb_1c2m_1800 = "";
            this.tbb_4m_1800 = "";
            this.tbb_7m_1800 = "";
            this.tbb_10m_1800 = "";
            this.rh_1c2m_1800 = "";
            this.rh_4m_1800 = "";
            this.rh_7m_1800 = "";
            this.rh_10m_1800 = "";
            // this.ws_avg_0c5_1800 = ""
            // this.counter_0c5_1800 = ""
            // this.counter_sebelum_0c5_1800 = ""
            this.ws_avg_4_1800 = "";
            this.counter_4_1800 = "";
            this.counter_sebelum_4_1800 = "";
            this.ws_avg_7_1800 = "";
            this.counter_7_1800 = "";
            this.counter_sebelum_7_1800 = "";
            this.ws_avg_10_1800 = "";
            this.counter_10_1800 = "";
            this.counter_sebelum_10_1800 = "";
            this.t_max_1c2m = "";
            this.t_max_4m = "";
            this.t_max_7m = "";
            this.t_max_10m = "";
            this.t_reset_1c2m_1800 = "";
            this.wd_1800 = "";
            this.ws_1800 = "";
            this.pp_qfe_0000 = "";
            this.counter_sebelum_7_2_1800 = "";
            this.ws_avg_7_2_1800 = "";
            this.is_edit_pp_qfe_0000 = false;
            this.flagdata_fklim = null;
            this.flagdata_iklim_mikro = null;
            this.flagdata_agm1a = null;
            this.LIST_QC_FIX = {};
            this.LIST_QC_NOTIFICATION_FIX = {};

            this.$v.$reset();
        },
        validateState(item) {
            const { $dirty, $error } = item;
            return $dirty ? !$error : null;
        },
        cancelForm() {
            this.clearData();
            this.$emit("form", "test");
        },
        validationForm() {
            // this.$v.$touch();
            // this.$v.tbk_1c2m_1800.$touch();
            // this.$v.tbk_4m_1800.$touch();
            // this.$v.tbk_7m_1800.$touch();
            // this.$v.tbk_10m_1800.$touch();

            // this.$v.tbb_1c2m_1800.$touch();
            // this.$v.tbb_4m_1800.$touch();
            // this.$v.tbb_7m_1800.$touch();
            // this.$v.tbb_10m_1800.$touch();

            // // this.$v.counter_0c5_1800.$touch();
            // this.$v.counter_4_1800.$touch();
            // this.$v.counter_7_1800.$touch();
            // this.$v.counter_10_1800.$touch();

            // this.$v.t_max_1c2m.$touch();
            // this.$v.t_max_4m.$touch();
            // this.$v.t_max_7m.$touch();
            // this.$v.t_max_10m.$touch();
            // this.$v.t_reset_1c2m_1800.$touch();

            // this.$v.wd_1800.$touch();
            // this.$v.ws_1800.$touch();

            // this.$v.pp_qfe_0000.$touch();

            // let valid = false;
            // let messageError = "";
            // for (let param in this.$v) {
            //   if (!param.startsWith("$")) {
            //     let hasRequired = this.$v[param].hasOwnProperty("required");
            //     // console.log('validationForm - ', param, hasRequired)
            //     if (hasRequired) {
            //       let required = this.$v[param].required;
            //       valid = required ? (this.$v[param].$anyError ? false : true) : true;
            //       messageError = valid ? "" : "Data " + param + " tidak valid. Periksa kembali inputan anda";
            //     } else {
            //       valid = this.$v[param].$anyError ? false : true;
            //       messageError = valid ? "" : "Data " + param + " tidak valid. Periksa kembali inputan anda";
            //     }
            //     // let eror = ""
            //     if (!valid) {
            //       break;
            //     }
            //   }
            // }

            // let valid = true;
            // let messageError = "Data tidak valid. Periksa kembali inputan anda";

            // if (this.$v.$invalid) {
            //   console.log("this.$v", this.$v);
            //   valid = false;
            // }
            let valid = true;
            let messageError = "";

            if (this.fdih_status.is_fklim) {
                this.$v.tbk_1c2m_1800.$touch();
                this.$v.tbb_1c2m_1800.$touch();
                this.$v.t_max_1c2m.$touch();
                this.$v.pp_qfe_0000.$touch();

                if (this.$v.tbk_1c2m_1800.$invalid || this.$v.tbb_1c2m_1800.$invalid || this.$v.t_max_1c2m.$invalid || this.$v.pp_qfe_0000.$invalid) {
                    valid = false;
                    messageError = "Data tidak valid. Periksa kembali inputan anda";
                }
            }

            if (this.fdih_status.is_agm1a) {
                let is_counter_7_2_1800_invalid = false;
                this.$v.tbk_1c2m_1800.$touch();
                this.$v.tbb_1c2m_1800.$touch();
                if (!this.fdih_status.is_iklim_mikro) {
                    this.$v.counter_7_2_1800.$touch();
                    is_counter_7_2_1800_invalid = this.$v.counter_7_2_1800.$invalid;
                }
                this.$v.wd_1800.$touch();
                this.$v.ws_1800.$touch();
                this.$v.t_reset_1c2m_1800.$touch();

                if (this.$v.tbk_1c2m_1800.$invalid || this.$v.tbb_1c2m_1800.$invalid || is_counter_7_2_1800_invalid || this.$v.wd_1800.$invalid || this.$v.ws_1800.$invalid || this.$v.t_reset_1c2m_1800.$invalid) {
                    valid = false;
                    messageError = "Data tidak valid. Periksa kembali inputan anda";
                }
            }

            if (this.fdih_status.is_iklim_mikro) {
                this.$v.tbk_4m_1800.$touch();
                this.$v.tbb_4m_1800.$touch();
                this.$v.tbk_7m_1800.$touch();
                this.$v.tbb_7m_1800.$touch();
                this.$v.tbk_10m_1800.$touch();
                this.$v.tbb_10m_1800.$touch();
                this.$v.counter_4_1800.$touch();
                this.$v.counter_7_1800.$touch();
                this.$v.counter_10_1800.$touch();
                this.$v.t_max_4m.$touch();
                this.$v.t_max_7m.$touch();
                this.$v.t_max_10m.$touch();

                if (
                    this.$v.tbk_4m_1800.$invalid ||
                    this.$v.tbb_4m_1800.$invalid ||
                    this.$v.tbk_7m_1800.$invalid ||
                    this.$v.tbb_7m_1800.$invalid ||
                    this.$v.tbk_10m_1800.$invalid ||
                    this.$v.tbb_10m_1800.$invalid ||
                    this.$v.counter_4_1800.$invalid ||
                    this.$v.counter_7_1800.$invalid ||
                    this.$v.counter_10_1800.$invalid ||
                    this.$v.t_max_4m.$invalid ||
                    this.$v.t_max_7m.$invalid ||
                    this.$v.t_max_10m.$invalid
                ) {
                    valid = false;
                    messageError = "Data tidak valid. Periksa kembali inputan anda";
                }
            }

            // console.log("this.$v", this.$v);

            if (!valid) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: messageError, //"Data tidak valid. Periksa kembali inputan anda",
                        icon: "BellIcon",
                        variant: "danger",
                    },
                });
            } else {
                // console.log("this.DATA_RC", this.DATA_RC);
                let qc_flag = Object.keys(this.DATA_RC).length > 0 ? 1 : 0;
                let list_qc = this.getQCExist(); //qc_flag > 0 ? this.getQCExist() : null;
                this.LIST_QC_FIX = list_qc && Object.keys(list_qc).length > 0 ? list_qc : null;
                let msg_html = this.getHtmlMessage(list_qc);

                if (qc_flag == 1) {
                    let qc_histories = {
                        before: null,
                        after: list_qc,
                    };

                    this.$swal({
                        title: "Apakah Anda yakin untuk simpan data ?",
                        text: "Ada Data Terkoreksi Range Check ",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonText: "Submit",
                        cancelButtonText: "Cek Data",
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        customClass: {
                            confirmButton: "btn btn-primary",
                            cancelButton: "btn btn-outline-danger ml-1",
                        },
                        buttonsStyling: false,
                    }).then((result) => {
                        if (result.value) {
                            this.submitFDIH1800();
                        } else if (result.dismiss === "cancel") {
                            return;
                        }
                    });
                } else {
                    this.submitFDIH1800();
                }

                // this.submitFDIH1800();
            }
        },
        // rumus_Es(val) {
        //   return 6.11 * Math.pow(10, (7.5 * val) / 10 / (2373.3 + val / 10));
        // },
        // rumus_E(es, tbk, tbb) {
        //   return es - 0.0007947 * (tbk / 10 - tbb / 10) * 1000;
        // },

        getHtmlMessage(data) {
            let html = "";
            if (data && data.length > 0) {
                for (let dt of data) {
                    html += "<div>" + dt.atribut + ": " + dt.message + " </div>";
                }
                return html;
            }

            return "<div />";
        },

        getQCExist() {
            // if (Object.keys(this.DATA_RC).length > 0) {
            let qc_iklimmikro = Object.entries(this.DATA_RC)
                .filter((e) => e[1].status && (e[0] == "t_max_4m" || e[0] == "t_max_7m" || e[0] == "t_max_10m" || e[0] == "tbk_4m_1800" || e[0] == "tbk_7m_1800" || e[0] == "tbk_10m_1800"))
                .map((e) => {
                    let param = e[0];
                    let content = e[1];
                    return {
                        raw_type: "IKLIM_MIKRO",
                        time: "1800ws",
                        atribut: "iklimmikro_" + param,
                        atribut_name: param,
                        value: this[param],
                        roles: content.roles, //RC_FDIH_ROLES(param),
                        type: "range_check",
                        message: content.message,
                    };
                });

            let qc_agm1a = Object.entries(this.DATA_RC)
                .filter((e) => e[1].status && (e[0] == "t_max_1c2m" || e[0] == "t_reset_1c2m_1800" || e[0] == "tbk_1c2m_1800" || e[0] == "tbb_1c2m_1800" || e[0] == "ws_1800" || e[0] == "wd_1800"))
                .map((e) => {
                    let param = e[0];
                    let content = e[1];
                    return {
                        raw_type: "AGM1A",
                        time: "1800ws",
                        atribut: "agm1a_" + param,
                        atribut_name: param,
                        value: this[param],
                        roles: content.roles, //RC_FDIH_ROLES(param),
                        type: "range_check",
                        message: content.message,
                    };
                });

            let qc_fklim = Object.entries(this.DATA_RC)
                .filter((e) => e[1].status && (e[0] == "tbk_1c2m_1800" || e[0] == "t_max_1c2m"))
                .map((e) => {
                    let param = e[0];
                    let content = e[1];
                    return {
                        raw_type: "FKLIM",
                        time: "1800ws",
                        atribut: "fklim_" + param,
                        atribut_name: param,
                        value: this[param],
                        roles: content.roles, //RC_FDIH_ROLES(param),
                        type: "range_check",
                        message: content.message,
                    };
                });

            let allform = {};

            // if (qc_iklimmikro && qc_iklimmikro.length > 0) allform['iklimmikro'] = qc_iklimmikro
            if (qc_iklimmikro && qc_iklimmikro.length > 0) {
                let exist = this.t_qc_histories?.iklimmikro;
                exist = exist?.after;
                if (exist && exist.length > 0) {
                    var filtered = exist.filter(function (e) {
                        return (
                            e.atribut != "t_max_4m" &&
                            e.atribut != "iklimmikro_t_max_4m" &&
                            e.atribut != "t_max_7m" &&
                            e.atribut != "iklimmikro_t_max_7m" &&
                            e.atribut != "t_max_10m" &&
                            e.atribut != "iklimmikro_t_max_10m" &&
                            e.atribut != "tbk_4m_1800" &&
                            e.atribut != "iklimmikro_tbk_4m_1800" &&
                            e.atribut != "tbk_7m_1800" &&
                            e.atribut != "iklimmikro_tbk_7m_1800" &&
                            e.atribut != "tbk_10m_1800" &&
                            e.atribut != "iklimmikro_tbk_10m_1800"
                        );
                    });
                    for (let data of qc_iklimmikro) {
                        var index = filtered.findIndex((x) => (x.hasOwnProperty("atribut_name") ? x.atribut_name == data.atribut_name : x.atribut == data.atribut_name));
                        if (index === -1) {
                            filtered.push(data);
                        } else {
                            filtered[index]["atribut"] = data["atribut"];
                            filtered[index]["atribut_name"] = data["atribut_name"];
                        }
                    }
                    allform["iklimmikro"] = filtered;
                } else {
                    allform["iklimmikro"] = qc_iklimmikro;
                }
            } else {
                // ketika current kosong, tetapi masih ada histori yg lama
                let exist = this.t_qc_histories?.iklimmikro;
                exist = exist?.after;
                if (exist && exist.length > 0) {
                    var filtered = exist.filter(function (e) {
                        return (
                            e.atribut != "t_max_4m" &&
                            e.atribut != "iklimmikro_t_max_4m" &&
                            e.atribut != "t_max_7m" &&
                            e.atribut != "iklimmikro_t_max_7m" &&
                            e.atribut != "t_max_10m" &&
                            e.atribut != "iklimmikro_t_max_10m" &&
                            e.atribut != "tbk_4m_1800" &&
                            e.atribut != "iklimmikro_tbk_4m_1800" &&
                            e.atribut != "tbk_7m_1800" &&
                            e.atribut != "iklimmikro_tbk_7m_1800" &&
                            e.atribut != "tbk_10m_1800" &&
                            e.atribut != "iklimmikro_tbk_10m_1800"
                        );
                    });
                    allform["iklimmikro"] = filtered;
                } else {
                    allform["iklimmikro"] = null;
                }
            }

            // if (qc_agm1a && qc_agm1a.length > 0) allform['agm1a'] = qc_agm1a
            if (qc_agm1a && qc_agm1a.length > 0) {
                let exist = this.t_qc_histories?.agm1a;
                exist = exist?.after;
                if (exist && exist.length > 0) {
                    var filtered = exist.filter(function (e) {
                        return (
                            e.atribut != "t_max_1c2m" &&
                            e.atribut != "agm1a_t_max_1c2m" &&
                            e.atribut != "t_reset_1c2m_1800" &&
                            e.atribut != "agm1a_t_reset_1c2m_1800" &&
                            e.atribut != "tbk_1c2m_1800" &&
                            e.atribut != "agm1a_tbk_1c2m_1800" &&
                            e.atribut != "tbb_1c2m_1800" &&
                            e.atribut != "agm1a_tbb_1c2m_1800" &&
                            (e.atribut != "ws_1800") & (e.atribut != "agm1a_ws_1800") &&
                            e.atribut != "wd_1800" &&
                            e.atribut != "agm1a_wd_1800"
                        );
                    });
                    for (let data of qc_agm1a) {
                        var index = filtered.findIndex((x) => (x.hasOwnProperty("atribut_name") ? x.atribut_name == data.atribut_name : x.atribut == data.atribut_name));
                        if (index === -1) {
                            filtered.push(data);
                        } else {
                            filtered[index]["atribut"] = data["atribut"];
                            filtered[index]["atribut_name"] = data["atribut_name"];
                        }
                    }
                    allform["agm1a"] = filtered;
                } else {
                    allform["agm1a"] = qc_agm1a;
                }
            } else {
                // ketika current kosong, tetapi masih ada histori yg lama
                let exist = this.t_qc_histories?.agm1a;
                exist = exist?.after;
                if (exist && exist.length > 0) {
                    var filtered = exist.filter(function (e) {
                        return (
                            e.atribut != "t_max_1c2m" &&
                            e.atribut != "agm1a_t_max_1c2m" &&
                            e.atribut != "t_reset_1c2m_1800" &&
                            e.atribut != "agm1a_t_reset_1c2m_1800" &&
                            e.atribut != "tbk_1c2m_1800" &&
                            e.atribut != "agm1a_tbk_1c2m_1800" &&
                            e.atribut != "tbb_1c2m_1800" &&
                            e.atribut != "agm1a_tbb_1c2m_1800" &&
                            (e.atribut != "ws_1800") & (e.atribut != "agm1a_ws_1800") &&
                            e.atribut != "wd_1800" &&
                            e.atribut != "agm1a_wd_1800"
                        );
                    });
                    allform["agm1a"] = filtered;
                } else {
                    allform["agm1a"] = null;
                }
            }

            if (qc_fklim && qc_fklim.length > 0) {
                let exist = this.t_qc_histories?.fklim;
                exist = exist?.after;
                if (exist && exist.length > 0) {
                    var filtered = exist.filter(function (e) {
                        return e.atribut != "tbk_1c2m_1800" && e.atribut != "fklim_tbk_1c2m_1800" && e.atribut != "t_max_1c2m" && e.atribut != "fklim_t_max_1c2m";
                    });
                    for (let data of qc_fklim) {
                        var index = filtered.findIndex((x) => (x.hasOwnProperty("atribut_name") ? x.atribut_name == data.atribut_name : x.atribut == data.atribut_name));
                        if (index === -1) {
                            filtered.push(data);
                        } else {
                            filtered[index]["atribut"] = data["atribut"];
                            filtered[index]["atribut_name"] = data["atribut_name"];
                        }
                    }
                    allform["fklim"] = filtered;
                } else {
                    allform["fklim"] = qc_fklim;
                }
            } else {
                // ketika current kosong, tetapi masih ada histori yg lama
                let exist = this.t_qc_histories?.fklim;
                exist = exist?.after;
                if (exist && exist.length > 0) {
                    var filtered = exist.filter(function (e) {
                        return e.atribut != "tbk_1c2m_1800" && e.atribut != "fklim_tbk_1c2m_1800" && e.atribut != "t_max_1c2m" && e.atribut != "fklim_t_max_1c2m";
                    });
                    allform["fklim"] = filtered;
                } else {
                    allform["fklim"] = null;
                }
            }

            return allform;
            // }
            // return null;
        },

        submitFDIH1800() {
            const fdih_id = this.t_id_form; //"1_fdih_" + this.t_date;
            const data_timestamp = this.tdate;
            let post_data = {
                data_timestamp: data_timestamp,
                "@type": "Fdih",
                id: fdih_id,
                observer_id: this.t_observer.id,
                observer_name: this.t_observer.observer,
                m_1800ws: {
                    tbk_1c2m_1800: this.tbk_1c2m_1800,
                    tbk_4m_1800: this.tbk_4m_1800,
                    tbk_7m_1800: this.tbk_7m_1800,
                    tbk_10m_1800: this.tbk_10m_1800,
                    tbb_1c2m_1800: this.tbb_1c2m_1800,
                    tbb_4m_1800: this.tbb_4m_1800,
                    tbb_7m_1800: this.tbb_7m_1800,
                    tbb_10m_1800: this.tbb_10m_1800,
                    rh_1c2m_1800: this.rh_1c2m_1800,
                    rh_4m_1800: this.rh_4m_1800,
                    rh_7m_1800: this.rh_7m_1800,
                    rh_10m_1800: this.rh_10m_1800,
                    // ws_avg_0c5_1800: this.ws_avg_0c5_1800,
                    // counter_0c5_1800: this.counter_0c5_1800,
                    // counter_sebelum_0c5_1800: this.counter_sebelum_0c5_1800,

                    ws_avg_4_1800: this.ws_avg_4_1800,
                    counter_4_1800: this.counter_4_1800,
                    counter_sebelum_4_1800: this.counter_sebelum_4_1800,
                    ws_avg_7_1800: this.ws_avg_7_1800,
                    counter_7_1800: this.counter_7_1800 ? this.counter_7_1800 : this.counter_7_2_1800,
                    counter_sebelum_7_1800: this.counter_sebelum_7_1800,
                    ws_avg_7_2_1800: this.ws_avg_7_2_1800,
                    counter_sebelum_7_2_1800: this.counter_sebelum_7_2_1800,
                    ws_avg_10_1800: this.ws_avg_10_1800,
                    counter_10_1800: this.counter_10_1800,
                    counter_sebelum_10_1800: this.counter_sebelum_10_1800,
                    t_max_1c2m: this.t_max_1c2m,
                    t_max_4m: this.t_max_4m,
                    t_max_7m: this.t_max_7m,
                    t_max_10m: this.t_max_10m,
                    t_reset_1c2m_1800: this.t_reset_1c2m_1800,
                    wd_1800: this.wd_1800,
                    ws_1800: this.ws_1800,
                    pp_qfe_0000: this.pp_qfe_0000,
                    flagm_1800: 1,
                },
            };

            if (this.t_data_status == "update") {
                post_data = {
                    data_timestamp: data_timestamp,
                    observer_id: this.t_observer.id,
                    observer_name: this.t_observer.observer,
                    m_1800ws: {
                        tbk_1c2m_1800: this.tbk_1c2m_1800,
                        tbk_4m_1800: this.tbk_4m_1800,
                        tbk_7m_1800: this.tbk_7m_1800,
                        tbk_10m_1800: this.tbk_10m_1800,
                        tbb_1c2m_1800: this.tbb_1c2m_1800,
                        tbb_4m_1800: this.tbb_4m_1800,
                        tbb_7m_1800: this.tbb_7m_1800,
                        tbb_10m_1800: this.tbb_10m_1800,
                        rh_1c2m_1800: this.rh_1c2m_1800,
                        rh_4m_1800: this.rh_4m_1800,
                        rh_7m_1800: this.rh_7m_1800,
                        rh_10m_1800: this.rh_10m_1800,
                        // ws_avg_0c5_1800: this.ws_avg_0c5_1800,
                        // counter_0c5_1800: this.counter_0c5_1800,
                        // counter_sebelum_0c5_1800: this.counter_sebelum_0c5_1800,

                        ws_avg_4_1800: this.ws_avg_4_1800,
                        counter_4_1800: this.counter_4_1800,
                        counter_sebelum_4_1800: this.counter_sebelum_4_1800,
                        ws_avg_7_1800: this.ws_avg_7_1800,
                        counter_7_1800: this.counter_7_1800,
                        counter_sebelum_7_1800: this.counter_sebelum_7_1800,
                        ws_avg_7_2_1800: this.ws_avg_7_2_1800,
                        counter_sebelum_7_2_1800: this.counter_sebelum_7_2_1800,
                        ws_avg_10_1800: this.ws_avg_10_1800,
                        counter_10_1800: this.counter_10_1800,
                        counter_sebelum_10_1800: this.counter_sebelum_10_1800,
                        t_max_1c2m: this.t_max_1c2m,
                        t_max_4m: this.t_max_4m,
                        t_max_7m: this.t_max_7m,
                        t_max_10m: this.t_max_10m,
                        t_reset_1c2m_1800: this.t_reset_1c2m_1800,
                        wd_1800: this.wd_1800,
                        ws_1800: this.ws_1800,
                        pp_qfe_0000: this.pp_qfe_0000,
                        flagm_1800: 1,
                    },
                };
            }

            let list_qc_notification = {};
            this.LIST_QC_NOTIFICATION_FIX = {};
            let regionId = this.t_path.split("/")[1];
            if (this.LIST_QC_FIX && Object.keys(this.LIST_QC_FIX).length > 0) {
                if (this.LIST_QC_FIX.hasOwnProperty("agm1a")) {
                    post_data["BmkgSatu.behaviors.qc.IFdih_Agm1a_qc"] = {
                        qc_flag_agm1a: this.flagdata_agm1a == 2 ? 2 : this.LIST_QC_FIX.agm1a && this.LIST_QC_FIX.agm1a.length > 0 ? 1 : 0,
                        qc_flag_agm1a_1800: this.LIST_QC_FIX.agm1a && this.LIST_QC_FIX.agm1a.length > 0 ? 1 : 0,
                        qc_histories_agm1a: JSON.stringify({ before: null, after: this.LIST_QC_FIX.agm1a }),
                    };

                    this.LIST_QC_NOTIFICATION_FIX["agm1a"] = [
                        "agm1a_" + this.t_id_form, // id entry
                        "AGM1A", // type form entry
                        data_timestamp, // tanggal entry
                        "1800ws", // jam entry
                        JSON.stringify({ before: null, after: this.LIST_QC_FIX.agm1a }), // qc histories
                        1, // qc_flag
                        this.t_station_id, // station_id
                        this.t_station.text, //station_name
                        this.t_observer.id, // observer_id
                        this.t_observer.observer, //observer_name
                        regionId, // region_id
                        this.t_path + "/" + this.t_id_form, // path
                    ];
                }

                if (this.LIST_QC_FIX.hasOwnProperty("iklimmikro")) {
                    post_data["BmkgSatu.behaviors.qc.IFdih_IklimMikro_qc"] = {
                        qc_flag_iklim_mikro: this.flagdata_iklim_mikro == 2 ? 2 : this.LIST_QC_FIX.agm1a && this.LIST_QC_FIX.agm1a.length > 0 ? 1 : 0,
                        qc_flag_iklim_mikro_1800: this.LIST_QC_FIX.agm1a && this.LIST_QC_FIX.agm1a.length > 0 ? 1 : 0,
                        qc_histories_iklim_mikro: JSON.stringify({ before: null, after: this.LIST_QC_FIX.agm1a }),
                    };

                    this.LIST_QC_NOTIFICATION_FIX["iklimmikro"] = [
                        "iklimmikro_" + this.t_id_form, // id entry
                        "IKLIM_MIKRO", // type form entry
                        data_timestamp, // tanggal entry
                        "1800ws", // jam entry
                        JSON.stringify({ before: null, after: this.LIST_QC_FIX.iklimmikro }), // qc histories
                        1, // qc_flag
                        this.t_station_id, // station_id
                        this.t_station.text, //station_name
                        this.t_observer.id, // observer_id
                        this.t_observer.observer, //observer_name
                        regionId, // region_id
                        this.t_path + "/" + this.t_id_form, // path
                    ];
                }

                if (this.LIST_QC_FIX.hasOwnProperty("fklim")) {
                    post_data["BmkgSatu.behaviors.qc.IFdih_Fklim_qc"] = {
                        qc_flag_fklim: this.flagdata_fklim == 2 ? 2 : this.LIST_QC_FIX.fklim && this.LIST_QC_FIX.fklim.length > 0 ? 1 : 0,
                        qc_flag_fklim_1800: this.LIST_QC_FIX.fklim && this.LIST_QC_FIX.fklim.length > 0 ? 1 : 0,
                        qc_histories_fklim: JSON.stringify({ before: null, after: this.LIST_QC_FIX.fklim }),
                    };

                    this.LIST_QC_NOTIFICATION_FIX["fklim"] = [
                        "fklim_" + this.t_id_form, // id entry
                        "FKLIM", // type form entry
                        data_timestamp, // tanggal entry
                        "1800ws", // jam entry
                        JSON.stringify({ before: null, after: this.LIST_QC_FIX.fklim }), // qc histories
                        1, // qc_flag
                        this.t_station_id, // station_id
                        this.t_station.text, //station_name
                        this.t_observer.id, // observer_id
                        this.t_observer.observer, //observer_name
                        regionId, // region_id
                        this.t_path + "/" + this.t_id_form, // path
                    ];
                }
            }

            if (this.t_data_status == "update") {
                Service.setFDIH07301800(this.t_path + "/" + this.t_id_form, post_data)
                    .then((response) => {
                        if (response.status == 201 || response.status == 200 || response.status == 204) {
                            this.finishingRequest();
                            this.$emit("form", "test");
                            let titleMessage = "Input Data Berhasil!";
                            let txtMessage = "Input Data FDIH";
                            if (this.t_data_status == "update") {
                                titleMessage = "Update FDIH 1800 berhasil!";
                                txtMessage = "Update FDIH 1800";
                            }
                            this.$swal({
                                title: titleMessage,
                                text: txtMessage,
                                icon: "success",
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: false,
                            });
                        }
                    })
                    .catch((error) => {
                        let titleMessage = "";
                        let txtMessage = "";
                        if (error.response.status == 401) {
                            txtMessage = "Sesi anda telah habis. Logout dan silahkan login kembali";
                            if (this.t_data_status == "update") {
                                titleMessage = "Gagal Update. Unauthorized! ";
                            } else {
                                titleMessage = "Gagal proses submit. Unauthorized!";
                            }
                            this.$swal({
                                title: titleMessage,
                                text: txtMessage,
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: false,
                            });
                        } else if (error.response.status == 404) {
                            txtMessage = "404. Object Not Found";
                            if (this.t_data_status == "update") {
                                titleMessage = "Gagal Update!";
                            } else {
                                titleMessage = "Gagal entry!";
                            }
                            this.$swal({
                                title: titleMessage,
                                text: txtMessage,
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: false,
                            });
                        } else {
                            txtMessage = "" + error.response.status;
                            if (this.t_data_status == "update") {
                                titleMessage = "Gagal Update!";
                            } else {
                                titleMessage = "Gagal entry!";
                            }
                            this.$swal({
                                title: titleMessage,
                                text: txtMessage,
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: false,
                            });
                        }
                    });
            } else if (this.t_data_status == "new") {
                Service.setNewFDIH07001800(this.t_path, post_data)
                    .then((response) => {
                        if (response.status == 201 || response.status == 200 || response.status == 204) {
                            this.finishingRequest();
                            this.clearData();
                            this.$emit("form", "test");
                            let titleMessage = "Input Data Berhasil!";
                            let txtMessage = "Input Data FDIH";
                            this.$swal({
                                title: titleMessage,
                                text: txtMessage,
                                icon: "success",
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: false,
                            });

                            this.showLoading = false;
                        }
                    })
                    .catch((error) => {
                        if (error.response.status == 401) {
                            this.$swal({
                                title: "401. Unauthorized!",
                                text: "Sesi anda telah habis. Logout dan silahkan login kembali",
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: false,
                            });
                        } else if (error.response.status == 404) {
                            this.$swal({
                                title: "Gagal entry!",
                                text: "404. Object Not Found",
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: false,
                            });
                        } else {
                            this.$swal({
                                title: "Gagal entry!",
                                text: "" + error.response.status,
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: false,
                            });
                        }
                        this.showLoading = false;
                    });
            }
        },

        async finishingRequest() {
            if (this.LIST_QC_NOTIFICATION_FIX && Object.keys(this.LIST_QC_NOTIFICATION_FIX).length > 0) {
                for (let key in this.LIST_QC_NOTIFICATION_FIX) {
                    new Promise(async (resolve) => {
                        let data = await qc_helper.editQCNotification(
                            this.LIST_QC_NOTIFICATION_FIX[key][0],
                            this.LIST_QC_NOTIFICATION_FIX[key][1],
                            this.LIST_QC_NOTIFICATION_FIX[key][2],
                            this.LIST_QC_NOTIFICATION_FIX[key][3],
                            this.LIST_QC_NOTIFICATION_FIX[key][4],
                            this.LIST_QC_NOTIFICATION_FIX[key][5],
                            this.LIST_QC_NOTIFICATION_FIX[key][6],
                            this.LIST_QC_NOTIFICATION_FIX[key][7],
                            this.LIST_QC_NOTIFICATION_FIX[key][8],
                            this.LIST_QC_NOTIFICATION_FIX[key][9],
                            this.LIST_QC_NOTIFICATION_FIX[key][10],
                            this.LIST_QC_NOTIFICATION_FIX[key][11]
                        );
                        resolve(data);
                    });
                }
            }
        },

        checkValidateAll() {
            this.checkValidate({ target: { value: this.tbk_1c2m_1800 } }, "tbk_1c2m_1800");
            this.checkValidate({ target: { value: this.tbb_1c2m_1800 } }, "tbb_1c2m_1800");
            this.checkValidate({ target: { value: this.tbk_4m_1800 } }, "tbk_4m_1800");
            this.checkValidate({ target: { value: this.tbk_7m_1800 } }, "tbk_7m_1800");
            this.checkValidate({ target: { value: this.tbk_10m_1800 } }, "tbk_10m_1800");
            this.checkValidate({ target: { value: this.ws_1800 } }, "ws_1800");
            this.checkValidate({ target: { value: this.t_max_1c2m } }, "t_max_1c2m");
            this.checkValidate({ target: { value: this.t_reset_1c2m_1800 } }, "t_reset_1c2m_1800");
            this.checkValidate({ target: { value: this.t_max_4m } }, "t_max_4m");
            this.checkValidate({ target: { value: this.t_max_7m } }, "t_max_7m");
            this.checkValidate({ target: { value: this.t_max_10m } }, "t_max_10m");
        },
    },

    watch: {
        data_input: {
            deep: true,
            handler() {
                // console.log("data_input: ", this.data_input.i_time);
                if (this.data_input.i_time == "1800ws") {
                    this.clearData();
                }
            },
        },

        is_edit_pp_qfe_0000: function (val) {
            // console.log('this.is_edit_pp_qfe_0000', val)
            if (!val) {
                // console.log('this.is_edit_pp_qfe_0000', this.is_edit_pp_qfe_0000)
                if (this.t_data_existing && this.t_data_existing != "") {
                    this.pp_qfe_0000 = this.t_data_existing.pp_qfe_0000;
                } else if (this.t_data_requirement && this.t_data_requirement != "") {
                    this.pp_qfe_0000 = this.t_data_requirement.sinop_pressure_qfe_mb_derived ? this.t_data_requirement.sinop_pressure_qfe_mb_derived : this.pp_qfe_0000;
                }
            }
        },

        t_bmkgentry: function (val) {
            this.initialize();
        },

        t_all_data: function (val) {
            this.flagdata_fklim = val["BmkgSatu.behaviors.qc.IFdih_Fklim_qc"].qc_flag_fklim;
            this.flagdata_iklim_mikro = val["BmkgSatu.behaviors.qc.IFdih_IklimMikro_qc"].qc_flag_iklim_mikro;
            this.flagdata_agm1a = val["BmkgSatu.behaviors.qc.IFdih_Agm1a_qc"].qc_flag_agm1a;
        },

        t_data_requirement: function (val) {
            // this.counter_sebelum_0c5_1800 = val.fdih_ws1800_counter_0c5_1800 == "null" ? 0 : val.fdih_ws1800_counter_0c5_1800;

            // this.counter_sebelum_4_1800 = val.fdih_ws1800_counter_4_1800 == "null" ? 0 : val.fdih_ws1800_counter_4_1800;
            // this.counter_sebelum_7_1800 = val.fdih_ws1800_counter_7_1800 == "null" ? 0 : val.fdih_ws1800_counter_7_1800;
            // this.counter_sebelum_7_2_1800 = val.fdih_ws1800_counter_7_2_1800 == "null" ? 0 : val.fdih_ws1800_counter_7_2_1800;
            // this.counter_sebelum_10_1800 = val.fdih_ws1800_counter_10_1800 == "null" ? 0 : val.fdih_ws1800_counter_10_1800;

            if (val.fdih_ws1800_counter_4_1800 != "null" && val.fdih_ws1800_counter_4_1800 != null) {
                if (this.counter_sebelum_4_1800 === null || this.counter_sebelum_4_1800 === "null" || this.counter_sebelum_4_1800 === "") {
                    this.counter_sebelum_4_1800 = val.fdih_ws1800_counter_4_1800;
                }
            }

            if (val.fdih_ws1800_counter_7_1800 != "null" && val.fdih_ws1800_counter_7_1800 != null) {
                if (this.counter_sebelum_7_1800 === null || this.counter_sebelum_7_1800 === "null" || this.counter_sebelum_7_1800 === "") {
                    this.counter_sebelum_7_1800 = val.fdih_ws1800_counter_7_1800;
                }
            }

            if (val.fdih_ws1800_counter_7_2_1800 != "null" && val.fdih_ws1800_counter_7_2_1800 != null) {
                if (this.counter_sebelum_7_2_1800 === null || this.counter_sebelum_7_2_1800 === "null" || this.counter_sebelum_7_2_1800 === "") {
                    this.counter_sebelum_7_2_1800 = val.fdih_ws1800_counter_7_2_1800;
                }
            }

            if (val.fdih_ws1800_counter_10_1800 != "null" && val.fdih_ws1800_counter_10_1800 != null) {
                if (this.counter_sebelum_10_1800 === null || this.counter_sebelum_10_1800 === "null" || this.counter_sebelum_10_1800 === "") {
                    this.counter_sebelum_10_1800 = val.fdih_ws1800_counter_10_1800;
                }
            }

            if (this.pp_qfe_0000 == 0 || this.pp_qfe_0000 == "" || this.pp_qfe_0000 == "null" || this.pp_qfe_0000 == val.sinop_pressure_qfe_mb_derived) {
                this.is_edit_pp_qfe_0000 = false;
                this.pp_qfe_0000 = val.sinop_pressure_qfe_mb_derived;
            } else {
                this.is_edit_pp_qfe_0000 = true;
            }
            this.$v.$reset();
            this.checkValidateAll();
            // if (this.t_data_status == "update") {
            //     console.log('this.t_data_status == "update"');
            //     // this.$v.$touch();
            // }
        },

        t_data_existing: function (val) {
            this.tbk_1c2m_1800 = val.tbk_1c2m_1800;
            this.tbk_4m_1800 = val.tbk_4m_1800;
            this.tbk_7m_1800 = val.tbk_7m_1800;
            this.tbk_10m_1800 = val.tbk_10m_1800;
            this.tbb_1c2m_1800 = val.tbb_1c2m_1800;
            this.tbb_4m_1800 = val.tbb_4m_1800;
            this.tbb_7m_1800 = val.tbb_7m_1800;
            this.tbb_10m_1800 = val.tbb_10m_1800;
            this.rh_1c2m_1800 = val.rh_1c2m_1800;
            this.rh_4m_1800 = val.rh_4m_1800;
            this.rh_7m_1800 = val.rh_7m_1800;
            this.rh_10m_1800 = val.rh_10m_1800;
            //  this.ws_avg_0c5_1800 = val.ws_avg_0c5_1800
            //  this.counter_0c5_1800 = val.counter_0c5_1800
            //  this.counter_sebelum_0c5_1800 = val.counter_sebelum_0c5_1800
            this.ws_avg_4_1800 = val.ws_avg_4_1800;
            this.counter_4_1800 = val.counter_4_1800;
            this.counter_sebelum_4_1800 = val.counter_sebelum_4_1800;
            this.ws_avg_7_1800 = val.ws_avg_7_1800;
            this.counter_7_1800 = val.counter_7_1800;
            this.counter_sebelum_7_1800 = val.counter_sebelum_7_1800;
            this.ws_avg_7_2_1800 = val.ws_avg_7_2_1800;
            this.counter_sebelum_7_2_1800 = val.counter_sebelum_7_2_1800;
            this.ws_avg_10_1800 = val.ws_avg_10_1800;
            this.counter_10_1800 = val.counter_10_1800;
            this.counter_sebelum_10_1800 = val.counter_sebelum_10_1800;
            this.t_max_1c2m = val.t_max_1c2m;
            this.t_max_4m = val.t_max_4m;
            this.t_max_7m = val.t_max_7m;
            this.t_max_10m = val.t_max_10m;
            this.t_reset_1c2m_1800 = val.t_reset_1c2m_1800;
            this.wd_1800 = val.wd_1800;
            this.ws_1800 = val.ws_1800;
            this.pp_qfe_0000 = val.pp_qfe_0000;
            // tambahan dari Pargol
            this.$v.$touch();
        },

        tbk_1c2m_1800: function (val) {
            if (val == 9999) {
                this.rh_1c2m_1800 = 9999;
            } else {
                var Es = Helper.rumus_Es(this.tbb_1c2m_1800, val);
                var E = Helper.rumus_E(val).toFixed(2);
                this.rh_1c2m_1800 = this.tbb_1c2m_1800 == 9999 ? 9999 : Math.round((Es / E) * 100); //.toFixed(1);
            }
        },

        tbb_1c2m_1800: function (val) {
            if (val == 9999) {
                this.rh_1c2m_1800 = 9999;
            } else {
                var Es = Helper.rumus_Es(val, this.tbk_1c2m_1800);
                var E = Helper.rumus_E(this.tbk_1c2m_1800).toFixed(2);
                this.rh_1c2m_1800 = this.tbk_1c2m_1800 == 9999 ? 9999 : Math.round((Es / E) * 100); //.toFixed(1);
            }
        },

        tbk_4m_1800: function (val) {
            if (val == 9999) {
                this.rh_4m_1800 = 9999;
            } else {
                var Es = Helper.rumus_Es(this.tbb_4m_1800, val);
                var E = Helper.rumus_E(val).toFixed(2);
                this.rh_4m_1800 = this.tbb_4m_1800 == 9999 ? 9999 : Math.round((Es / E) * 100); //.toFixed(1);
            }
        },

        tbb_4m_1800: function (val) {
            if (val == 9999) {
                this.rh_4m_1800 = 9999;
            } else {
                var Es = Helper.rumus_Es(val, this.tbk_4m_1800);
                var E = Helper.rumus_E(this.tbk_4m_1800).toFixed(2);
                this.rh_4m_1800 = this.tbk_4m_1800 == 9999 ? 9999 : Math.round((Es / E) * 100); //.toFixed(1);
            }
        },

        tbk_7m_1800: function (val) {
            if (val == 9999) {
                this.rh_7m_1800 = 9999;
            } else {
                var Es = Helper.rumus_Es(this.tbb_7m_1800, val);
                var E = Helper.rumus_E(val).toFixed(2);
                this.rh_7m_1800 = this.tbb_7m_1800 == 9999 ? 9999 : Math.round((Es / E) * 100); //.toFixed(1);
            }
        },

        tbb_7m_1800: function (val) {
            if (val == 9999) {
                this.rh_7m_1800 = 9999;
            } else {
                var Es = Helper.rumus_Es(val, this.tbk_7m_1800);
                var E = Helper.rumus_E(this.tbk_7m_1800).toFixed(2);
                this.rh_7m_1800 = this.tbk_7m_1800 == 9999 ? 9999 : Math.round((Es / E) * 100); //.toFixed(1);
            }
        },

        tbk_10m_1800: function (val) {
            if (val == 9999) {
                this.rh_10m_1800 = 9999;
            } else {
                var Es = Helper.rumus_Es(this.tbb_10m_1800, val);
                var E = Helper.rumus_E(val).toFixed(2);
                this.rh_10m_1800 = this.tbb_10m_1800 == 9999 ? 9999 : Math.round((Es / E) * 100); //.toFixed(1);
            }
        },

        tbb_10m_1800: function (val) {
            if (val == 9999) {
                this.rh_10m_1800 = 9999;
            } else {
                var Es = Helper.rumus_Es(val, this.tbk_10m_1800);
                var E = Helper.rumus_E(this.tbk_10m_1800).toFixed(2);
                this.rh_10m_1800 = this.tbk_10m_1800 == 9999 ? 9999 : Math.round((Es / E) * 100); //.toFixed(1);
            }
        },
        // counter_0c5_1800: function(val) {
        //   this.ws_avg_0c5_1800 = ((val - this.counter_sebelum_0c5_1800) / 24).toFixed(2);
        // },
        is_counter_edit: function (val) {
            if (!val) {
                if (this.t_data_existing) {
                    this.counter_sebelum_4_1800 = this.t_data_existing.counter_sebelum_4_1800;
                    this.counter_sebelum_7_1800 = this.t_data_existing.counter_sebelum_7_1800;
                    this.counter_sebelum_7_2_1800 = this.t_data_existing.counter_sebelum_7_2_1800;
                    this.counter_sebelum_10_1800 = this.t_data_existing.counter_sebelum_10_1800;
                }
                if (this.t_data_requirement) {
                    this.counter_sebelum_4_1800 = this.t_data_requirement.fdih_ws1800_counter_4_1800 == "null" ? "" : this.t_data_requirement.fdih_ws1800_counter_4_1800;
                    this.counter_sebelum_7_1800 = this.t_data_requirement.fdih_ws1800_counter_7_1800 == "null" ? "" : this.t_data_requirement.fdih_ws1800_counter_7_1800;
                    this.counter_sebelum_7_2_1800 = this.t_data_requirement.fdih_ws1800_counter_7_2_1800 == "null" ? "" : this.t_data_requirement.fdih_ws1800_counter_7_2_1800;
                    this.counter_sebelum_10_1800 = this.t_data_requirement.fdih_ws1800_counter_10_1800 == "null" ? "" : this.t_data_requirement.fdih_ws1800_counter_10_1800;
                }

                if (this.counter_4_1800 != 9999 && this.counter_sebelum_4_1800 != 9999) {
                    this.ws_avg_4_1800 = parseFloat(((this.counter_4_1800 - this.counter_sebelum_4_1800) / 5).toFixed(3)).toFixed(2);
                }

                if (this.counter_7_1800 != 9999 && this.counter_sebelum_7_1800 != 9999) {
                    this.ws_avg_7_1800 = parseFloat(((this.counter_7_1800 - this.counter_sebelum_7_1800) / 5).toFixed(3)).toFixed(2);
                }

                if (this.counter_7_1800 != 9999 && this.counter_sebelum_7_2_1800 != 9999) {
                    this.ws_avg_7_2_1800 = parseFloat(((this.counter_7_1800 - this.counter_sebelum_7_2_1800) / 4).toFixed(3)).toFixed(2);
                }

                if (this.counter_10_1800 != 9999 && this.counter_sebelum_10_1800 != 9999) {
                    this.ws_avg_10_1800 = parseFloat(((this.counter_10_1800 - this.counter_sebelum_10_1800) / 5).toFixed(3)).toFixed(2);
                }
            }
        },

        counter_sebelum_4_1800: function (val) {
            if (val == 9999) {
                this.ws_avg_4_1800 = 9999;
            } else {
                this.ws_avg_4_1800 = this.counter_4_1800 == 9999 ? 9999 : parseFloat(((this.counter_4_1800 - val) / 5).toFixed(3)).toFixed(2);
            }
        },

        counter_sebelum_7_1800: function (val) {
            if (val == 9999) {
                this.ws_avg_7_1800 = 9999;
            } else {
                this.ws_avg_7_1800 = this.counter_7_1800 == 9999 ? 9999 : parseFloat(((this.counter_7_1800 - val) / 5).toFixed(3)).toFixed(2); // untuk agm1a
            }
        },

        counter_sebelum_7_2_1800: function (val) {
            if (val == 9999) {
                this.ws_avg_7_2_1800 = 9999;
            } else {
                this.ws_avg_7_2_1800 = this.counter_7_1800 == 9999 ? 9999 : parseFloat(((this.counter_7_1800 - val) / 4).toFixed(3)).toFixed(2); //untuk iklim mikro
            }
        },

        counter_sebelum_10_1800: function (val) {
            if (val == 9999) {
                this.ws_avg_10_1800 = 9999;
            } else {
                this.ws_avg_10_1800 = this.counter_10_1800 == 9999 ? 9999 : parseFloat(((this.counter_10_1800 - val) / 5).toFixed(3)).toFixed(2);
            }
        },

        counter_4_1800: function (val) {
            if (val == 9999) {
                this.ws_avg_4_1800 = 9999;
            } else {
                this.ws_avg_4_1800 = this.counter_sebelum_4_1800 == 9999 ? 9999 : parseFloat(((val - this.counter_sebelum_4_1800) / 5).toFixed(3)).toFixed(2);
            }
        },

        counter_7_1800: function (val) {
            if (val == 9999) {
                this.ws_avg_7_1800 = 9999;
                this.ws_avg_7_2_1800 = 9999;
            } else {
                this.ws_avg_7_1800 = this.counter_sebelum_7_1800 == 9999 ? 9999 : parseFloat(((val - this.counter_sebelum_7_1800) / 5).toFixed(3)).toFixed(2); // untuk agm1a
                this.ws_avg_7_2_1800 = this.counter_sebelum_7_2_1800 == 9999 ? 9999 : parseFloat(((val - this.counter_sebelum_7_2_1800) / 4).toFixed(3)).toFixed(2); //untuk iklim mikro
            }
        },

        counter_7_2_1800: function (val) {
            // console.log("counter_7_2_1800", val);
            if (val == 9999) {
                this.ws_avg_7_2_1800 = 9999;
            } else {
                this.ws_avg_7_2_1800 = this.counter_sebelum_7_2_1800 == 9999 ? 9999 : parseFloat(((val - this.counter_sebelum_7_2_1800) / 4).toFixed(3)).toFixed(2); //untuk iklim mikro
            }
        },

        counter_10_1800: function (val) {
            if (val == 9999) {
                this.ws_avg_10_1800 = 9999;
            } else {
                this.ws_avg_10_1800 = this.counter_sebelum_10_1800 == 9999 ? 9999 : parseFloat(((val - this.counter_sebelum_10_1800) / 5).toFixed(3)).toFixed(2);
            }
        },
    },
    directives: {
        "b-tooltip": VBTooltip,
        Ripple,
    },
};
</script>
<style scoped>
.label-entry1 {
    /* text-align: center; */
    color: #3b4253;
    font-weight: 500;
    width: 100%;
    /* border: 2px solid #6e6b7b; */
    border-radius: 3px;
    padding: 4px;
}
.label-entry2 {
    text-align: center;
    color: #3b4253;
    font-weight: 500;
    width: 100%;
    border: 2px solid #6e6b7b;
    border-radius: 3px;
    padding: 4px;
}

.input-dark-grey {
    background-color: #ebedf3;
}
.pad2 {
    padding-left: 2px;
    padding-right: 2px;
}
.bg-light-primary {
    color: #000000 !important;
}
.bg-light-success {
    color: #000000 !important;
}

#input_ws_1800.rangecheck {
    border-color: #ffeb3b;
    background-color: #fff494 !important;
}
#input_wd_1800.rangecheck {
    border-color: #ffeb3b;
    background-color: #fff494 !important;
}

#input_t_max_1c2m.rangecheck {
    border-color: #ffeb3b;
    background-color: #fff494 !important;
}
#input_t_max_4m.rangecheck {
    border-color: #ffeb3b;
    background-color: #fff494 !important;
}
#input_t_max_7m.rangecheck {
    border-color: #ffeb3b;
    background-color: #fff494 !important;
}
#input_t_max_10m.rangecheck {
    border-color: #ffeb3b;
    background-color: #fff494 !important;
}
#input_t_reset_1c2m_1800.rangecheck {
    border-color: #ffeb3b;
    background-color: #fff494 !important;
}

#input_tbk_1c2m_1800.rangecheck {
    border-color: #ffeb3b;
    background-color: #fff494 !important;
}

#input_tbb_1c2m_1800.rangecheck {
    border-color: #ffeb3b;
    background-color: #fff494 !important;
}

#input_tbk_4m_1800.rangecheck {
    border-color: #ffeb3b;
    background-color: #fff494 !important;
}

#input_tbk_7m_1800.rangecheck {
    border-color: #ffeb3b;
    background-color: #fff494 !important;
}

#input_tbk_10m_1800.rangecheck {
    border-color: #ffeb3b;
    background-color: #fff494 !important;
}
</style>
