<template>
  <div v-if="data_input.i_time == '1700ws'" style="font-size: 0.9rem">
    <b-row class="mt-n1">
      <!-- LYSIMETER start -->
      <b-col cols="12" class="mb-1">
        <b-card class="small font-weight-bolder h-100 text-dark">
          <h6 class="mb-2 small text-dark font-weight-bolder badge badge-light-dark">LYSIMETER</h6>
          <b-container v-if="fdih_status.is_lysimeter" fluid>
            <b-row>
              <b-col cols="10">
                <b-row class="">
                  <b-col cols="2"></b-col>
                  <b-col v-if="fdih_status.is_lysimeter_gundul" cols="2" class="text-center">
                    <div class="label-entry2 bg-light-primary">TANAH GUNDUL</div>
                  </b-col>
                  <b-col v-if="fdih_status.is_lysimeter_komoditi" cols="2" class="text-center">
                    <div class="label-entry2 bg-light-primary">TANAH KOMODITI</div>
                  </b-col>
                  <b-col v-if="fdih_status.is_lysimeter_berumput" cols="2" class="text-center">
                    <div class="label-entry2 bg-light-primary">TANAH BERUMPUT</div>
                  </b-col>
                </b-row>

                <b-row class="my-1">
                  <b-col cols="2">
                    <div class="label-entry1">
                      Siram
                      <feather-icon
                        icon="HelpCircleIcon"
                        size="12"
                        class="bg-primary rounded-circle text-white"
                        v-b-tooltip.hover.v-dark
                        title="Siram: volume air yang disiramkan 24 jam lalu ( 17.00 WS kemarin sampai 17.00 WS hari ini) pada lysimeter tanah gundul/tanah komoditi/tanah berumput dan dinyatakan dalam liter (identik dengan mm ketinggian air). "
                      />
                    </div>
                  </b-col>
                  <b-col v-if="fdih_status.is_lysimeter_gundul" cols="2">
                    <b-form-input
                      :formatter="format_one_decimal"
                      size="sm"
                      class="text-center"
                      id="input_s_gundul"
                      v-on:keydown="onKeyCheck($event)"
                      v-on:keyup="checkValidate($event, 's_gundul')"
                      v-bind:class="{ rangecheck: rc_s_gundul }"
                      :state="validateState($v.s_gundul)"
                      v-model.number="$v.s_gundul.$model"
                      :disabled="!fdih_status.is_lysimeter_gundul"
                    ></b-form-input>
                    <b-tooltip v-if="rc_s_gundul" target="input_s_gundul" triggers="hover">{{ rc_s_gundul_message }}</b-tooltip>
                    <b-tooltip :show="validateTooltipState('s_gundul')" :triggers="'hover'" target="input_s_gundul" placement="topleft" custom-class="validation-tooltip">
                      {{ tooltipMessage("s_gundul") }}
                    </b-tooltip>
                  </b-col>

                  <b-col v-if="fdih_status.is_lysimeter_komoditi" cols="2">
                    <b-form-input
                      :formatter="format_one_decimal"
                      size="sm"
                      class="text-center"
                      id="input_s_komoditi"
                      v-on:keydown="onKeyCheck($event)"
                      v-on:keyup="checkValidate($event, 's_komoditi')"
                      v-bind:class="{ rangecheck: rc_s_komoditi }"
                      :state="validateState($v.s_komoditi)"
                      v-model.number="$v.s_komoditi.$model"
                      :disabled="!fdih_status.is_lysimeter_komoditi"
                    ></b-form-input>
                    <b-tooltip v-if="rc_s_komoditi" target="input_s_komoditi" triggers="hover">{{ rc_s_komoditi_message }}</b-tooltip>
                    <b-tooltip :show="validateTooltipState('s_komoditi')" :triggers="'hover'" target="input_s_komoditi" placement="topleft" custom-class="validation-tooltip">
                      {{ tooltipMessage("s_komoditi") }}
                    </b-tooltip>
                  </b-col>
                  <b-col v-if="fdih_status.is_lysimeter_berumput" cols="2">
                    <b-form-input
                      :formatter="format_one_decimal"
                      size="sm"
                      class="text-center"
                      id="input_s_berumput"
                      v-on:keydown="onKeyCheck($event)"
                      v-on:keyup="checkValidate($event, 's_berumput')"
                      v-bind:class="{ rangecheck: rc_s_berumput }"
                      :state="validateState($v.s_berumput)"
                      v-model.number="$v.s_berumput.$model"
                      :disabled="!fdih_status.is_lysimeter_berumput"
                    ></b-form-input>
                    <b-tooltip v-if="rc_s_berumput" target="input_s_berumput" triggers="hover">{{ rc_s_berumput_message }}</b-tooltip>
                    <b-tooltip :show="validateTooltipState('s_berumput')" :triggers="'hover'" target="input_s_berumput" placement="topleft" custom-class="validation-tooltip">
                      {{ tooltipMessage("s_berumput") }}
                    </b-tooltip>
                  </b-col>
                </b-row>

                <b-row class="my-1">
                  <b-col cols="2">
                    <div class="label-entry1">
                      Perkolasi
                      <feather-icon
                        icon="HelpCircleIcon"
                        size="12"
                        class="bg-primary rounded-circle text-white"
                        v-b-tooltip.hover.v-dark
                        title="Perkolasi: hasil penyedotan air perkolasi pada lysimeter tanah gundul/tanah komoditi/tanah berumput selama 24 jam yang lalu, dinyatakan dalam liter (identik dengan mm ketinggian air). penyedotan air perkolasi dilakukan setiap hari jam 17.00 WS"
                      />
                    </div>
                  </b-col>
                  <b-col v-if="fdih_status.is_lysimeter_gundul" cols="2">
                    <b-form-input
                      :formatter="format_one_decimal"
                      size="sm"
                      class="text-center"
                      id="input_pk_gundul"
                      v-on:keydown="onKeyCheck($event)"
                      v-on:keyup="checkValidate($event, 'pk_gundul')"
                      v-bind:class="{ rangecheck: rc_pk_gundul }"
                      :state="validateState($v.pk_gundul)"
                      v-model.number="$v.pk_gundul.$model"
                      :disabled="!fdih_status.is_lysimeter_gundul"
                    ></b-form-input>
                    <b-tooltip v-if="rc_pk_gundul" target="input_pk_gundul" triggers="hover">{{ rc_pk_gundul_message }}</b-tooltip>
                    <b-tooltip :show="validateTooltipState('pk_gundul')" :triggers="'hover'" target="input_pk_gundul" placement="topleft" custom-class="validation-tooltip">
                      {{ tooltipMessage("pk_gundul") }}
                    </b-tooltip>
                  </b-col>
                  <b-col v-if="fdih_status.is_lysimeter_komoditi" cols="2">
                    <b-form-input
                      :formatter="format_one_decimal"
                      size="sm"
                      class="text-center"
                      id="input_pk_komoditi"
                      v-on:keydown="onKeyCheck($event)"
                      v-on:keyup="checkValidate($event, 'pk_komoditi')"
                      v-bind:class="{ rangecheck: rc_pk_komoditi }"
                      :state="validateState($v.pk_komoditi)"
                      v-model.number="$v.pk_komoditi.$model"
                      :disabled="!fdih_status.is_lysimeter_komoditi"
                    ></b-form-input>
                    <b-tooltip v-if="rc_pk_komoditi" target="input_pk_komoditi" triggers="hover">{{ rc_pk_komoditi_message }}</b-tooltip>
                    <b-tooltip :show="validateTooltipState('pk_komoditi')" :triggers="'hover'" target="input_pk_komoditi" placement="topleft" custom-class="validation-tooltip">
                      {{ tooltipMessage("pk_komoditi") }}
                    </b-tooltip>
                  </b-col>
                  <b-col v-if="fdih_status.is_lysimeter_berumput" cols="2">
                    <b-form-input
                      :formatter="format_one_decimal"
                      size="sm"
                      class="text-center"
                      id="input_pk_berumput"
                      v-on:keydown="onKeyCheck($event)"
                      v-on:keyup="checkValidate($event, 'pk_berumput')"
                      v-bind:class="{ rangecheck: rc_pk_berumput }"
                      :state="validateState($v.pk_berumput)"
                      v-model.number="$v.pk_berumput.$model"
                      :disabled="!fdih_status.is_lysimeter_berumput"
                    ></b-form-input>
                    <b-tooltip v-if="rc_pk_berumput" target="input_pk_berumput" triggers="hover">{{ rc_pk_berumput_message }}</b-tooltip>
                    <b-tooltip :show="validateTooltipState('pk_berumput')" :triggers="'hover'" target="input_pk_berumput" placement="topleft" custom-class="validation-tooltip">
                      {{ tooltipMessage("pk_berumput") }}
                    </b-tooltip>
                  </b-col>
                </b-row>

                <b-row class="my-1">
                  <b-col cols="2">
                    <div class="label-entry1">
                      Curah Hujan
                      <feather-icon
                        icon="HelpCircleIcon"
                        size="12"
                        class="bg-primary rounded-circle text-white"
                        v-b-tooltip.hover.v-dark
                        title="hasil peramatan curah hujan selama 24 jam yang lalu (17.00 WS kemarin sampai 17.00 WS hari ini) dinyatakan dalam mm"
                      />
                    </div>
                  </b-col>
                  <b-col v-if="fdih_status.is_lysimeter_gundul" cols="2">
                    <b-form-input
                      :formatter="format_one_decimal"
                      size="sm"
                      class="text-center"
                      id="input_rr_gundul"
                      v-on:keydown="onKeyCheck($event)"
                      v-on:keyup="checkValidate($event, 'rr_gundul')"
                      v-bind:class="{ rangecheck: rc_rr_gundul }"
                      :state="validateState($v.rr_gundul)"
                      v-model.number="$v.rr_gundul.$model"
                      :disabled="!fdih_status.is_lysimeter_gundul"
                    ></b-form-input>
                    <b-tooltip v-if="rc_rr_gundul" target="input_rr_gundul" triggers="hover">{{ rc_rr_gundul_message }}</b-tooltip>
                    <b-tooltip :show="validateTooltipState('rr_gundul')" :triggers="'hover'" target="input_rr_gundul" placement="topleft" custom-class="validation-tooltip">
                      {{ tooltipMessage("rr_gundul") }}
                    </b-tooltip>
                  </b-col>
                  <b-col v-if="fdih_status.is_lysimeter_komoditi" cols="2">
                    <b-form-input
                      :formatter="format_one_decimal"
                      size="sm"
                      class="text-center"
                      id="input_rr_komoditi"
                      v-on:keydown="onKeyCheck($event)"
                      v-on:keyup="checkValidate($event, 'rr_komoditi')"
                      v-bind:class="{ rangecheck: rc_rr_komoditi }"
                      :state="validateState($v.rr_komoditi)"
                      v-model.number="$v.rr_komoditi.$model"
                      :disabled="!fdih_status.is_lysimeter_komoditi"
                    ></b-form-input>
                    <b-tooltip v-if="rc_rr_komoditi" target="input_rr_komoditi" triggers="hover">{{ rc_rr_komoditi_message }}</b-tooltip>
                    <b-tooltip :show="validateTooltipState('rr_komoditi')" :triggers="'hover'" target="input_rr_komoditi" placement="topleft" custom-class="validation-tooltip">
                      {{ tooltipMessage("rr_komoditi") }}
                    </b-tooltip>
                  </b-col>
                  <b-col v-if="fdih_status.is_lysimeter_berumput" cols="2">
                    <b-form-input
                      :formatter="format_one_decimal"
                      size="sm"
                      class="text-center"
                      id="input_rr_berumput"
                      v-on:keydown="onKeyCheck($event)"
                      v-on:keyup="checkValidate($event, 'rr_berumput')"
                      v-bind:class="{ rangecheck: rc_rr_berumput }"
                      :state="validateState($v.rr_berumput)"
                      v-model.number="$v.rr_berumput.$model"
                      :disabled="!fdih_status.is_lysimeter_berumput"
                    ></b-form-input>
                    <b-tooltip v-if="rc_rr_berumput" target="input_rr_berumput" triggers="hover">{{ rc_rr_berumput_message }}</b-tooltip>
                    <b-tooltip :show="validateTooltipState('rr_berumput')" :triggers="'hover'" target="input_rr_berumput" placement="topleft" custom-class="validation-tooltip">
                      {{ tooltipMessage("rr_berumput") }}
                    </b-tooltip>
                  </b-col>
                </b-row>

                <b-row class="my-1">
                  <b-col cols="2">
                    <div class="label-entry1">
                      Evapotranspirasi
                      <feather-icon
                        icon="HelpCircleIcon"
                        size="12"
                        class="bg-primary rounded-circle text-white"
                        v-b-tooltip.hover.v-dark
                        title="Hasil perhitungan besar nya evapotranspirasi pada lysimeter tanah gundul/ tanah komoditi/tanah berumput dalam periode 24 jam yang lalu dinyatakan dalam mm. peritungan sebagaimana yang dimaksud yaitu : curah hujan + air siraman - air perkolasi"
                      />
                    </div>
                  </b-col>
                  <b-col v-if="fdih_status.is_lysimeter_gundul" cols="2">
                    <b-form-input :formatter="format_one_decimal" size="sm" class="bg-light-success text-center" v-model.number="eva_gundul" disabled></b-form-input>
                  </b-col>
                  <b-col v-if="fdih_status.is_lysimeter_komoditi" cols="2">
                    <b-form-input :formatter="format_one_decimal" size="sm" class="bg-light-success text-center" v-model.number="eva_komoditi" disabled></b-form-input>
                  </b-col>
                  <b-col v-if="fdih_status.is_lysimeter_berumput" cols="2">
                    <b-form-input :formatter="format_one_decimal" size="sm" class="bg-light-success text-center" v-model.number="eva_berumput" disabled></b-form-input>
                  </b-col>
                </b-row>

                <b-row class="my-1">
                  <b-col cols="2">
                    <div class="label-entry1">
                      Nama Tanaman Komoditi <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="nama tanaman yang terdapat pada lysimeter tanah komoditi" />
                    </div>
                  </b-col>
                  <b-col v-if="fdih_status.is_lysimeter_berumput && fdih_status.is_lysimeter_gundul && fdih_status.is_lysimeter_komoditi" cols="2">
                    <!-- <b-form-input type="text" size="sm"></b-form-input> -->
                  </b-col>
                  <b-col cols="2">
                    <b-form-input
                      v-if="fdih_status.is_lysimeter_komoditi"
                      id="name_komoditi"
                      type="text"
                      size="sm"
                      class="text-center"
                      :state="validateState($v.name_komoditi)"
                      v-model="$v.name_komoditi.$model"
                      :disabled="!fdih_status.is_lysimeter_komoditi"
                    ></b-form-input>
                    <!-- <b-tooltip :show="validateTooltipState('name_komoditi')" :triggers="'hover'" target="name_komoditi" placement="top" custom-class="validation-tooltip">
                      {{ tooltipMessage("name_komoditi") }}
                    </b-tooltip> -->
                  </b-col>
                  <b-col v-if="fdih_status.is_lysimeter_berumput && fdih_status.is_lysimeter_gundul && fdih_status.is_lysimeter_komoditi" cols="2">
                    <!-- <b-form-input type="text" size="sm"></b-form-input> -->
                  </b-col>
                </b-row>

                <b-row class="my-1">
                  <b-col cols="2">
                    <div class="label-entry1">
                      Catatan Lysimeter
                      <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="catatan mengenai kejadian yang terjadi selama pengamatan lysismeter (jika ada)" />
                    </div>
                  </b-col>
                  <b-col v-if="fdih_status.is_lysimeter_gundul" cols="2">
                    <b-form-input type="text" size="sm" class="text-center" v-model="cat_lysimeter_gundul" :disabled="!fdih_status.is_lysimeter_gundul"></b-form-input>
                  </b-col>
                  <b-col v-if="fdih_status.is_lysimeter_komoditi" cols="2">
                    <b-form-input type="text" size="sm" class="text-center" v-model="cat_lysimeter_komoditi" :disabled="!fdih_status.is_lysimeter_komoditi"></b-form-input>
                  </b-col>
                  <b-col v-if="fdih_status.is_lysimeter_berumput" cols="2">
                    <b-form-input type="text" size="sm" class="text-center" v-model="cat_lysimeter_berumput" :disabled="!fdih_status.is_lysimeter_berumput"></b-form-input>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-container>
        </b-card>
      </b-col>
      <!-- LYSIMETER end -->
    </b-row>

    <!-- button Action-->
    <b-row>
      <b-col cols="12">
        <b-card>
          <b-row class="float-right">
            <div>
              <b-button @click.prevent="cancelForm" variant="danger" class="mr-2" size="sm">Cancel</b-button>
              <!-- <b-button v-if="this.t_data_status == 'update' && fdih_status.is_lysimeter" @click="validationForm" variant="warning" class="mr-2" size="sm" :disabled="!roles.isRoleEdit"> Edit</b-button>
              <b-button v-if="this.t_data_status == 'new' && fdih_status.is_lysimeter" @click="validationForm" variant="primary" class="mr-2" size="sm" :disabled="!roles.isRoleAdd">Submit</b-button> -->
              <b-button v-if="fdih_status.is_lysimeter" @click="validationForm" variant="primary" class="mr-2" size="sm">Submit</b-button>
            </div>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-overlay :show="showLoading" rounded="sm" no-wrap> </b-overlay>
  </div>
</template>
<script>
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import Service from "@/api/fdihservice";
import Ripple from "vue-ripple-directive";
import axios from "axios";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
// import Params from "@/attr/metadata/params";
// import RC_helper from "@/validators/RC_helper";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { BCard, BCardHeader, BContainer, BRow, BCol, BFormSelect, BForm, BButton, BFormGroup, BCollapse, BFormDatepicker, BFormInput, VBTooltip, BTooltip, BIcon } from "bootstrap-vue";
import { GC_LYSIMETER_SIRAM, GC_LYSIMETER_PERKOLASI, GC_LYSIMETER_EVA, GC_LYSIMETER_CR_HUJAN, RC_FDIH, GC_FDIH } from "@/validators/fdihvalidator";
export default {
  components: {
    BCardActions,
    BCard,
    BCardHeader,
    BContainer,
    BRow,
    BCol,
    BFormSelect,
    BForm,
    BButton,
    BFormGroup,
    BCollapse,
    BFormDatepicker,
    BFormInput,
    VBTooltip,
    BTooltip,
    BIcon,
  },
  mixins: [validationMixin],
  validations: {
    // s_gundul: { required, GC_LYSIMETER_SIRAM },
    // s_komoditi: { required, GC_LYSIMETER_SIRAM },
    // s_berumput: { required, GC_LYSIMETER_SIRAM },
    // pk_gundul: { required, GC_LYSIMETER_PERKOLASI },
    // pk_komoditi: { required, GC_LYSIMETER_PERKOLASI },
    // pk_berumput: { required, GC_LYSIMETER_PERKOLASI },
    s_gundul: { required },
    s_komoditi: { required },
    s_berumput: { required },
    pk_gundul: { required },
    pk_komoditi: { required },
    pk_berumput: { required },
    // eva_gundul: { required, GC_LYSIMETER_EVA },
    // eva_komoditi: { required, GC_LYSIMETER_EVA },
    // eva_berumput: { required, GC_LYSIMETER_EVA },
    rr_gundul: { required },
    rr_komoditi: { required },
    rr_berumput: { required },
    // rr_gundul: { required, GC_LYSIMETER_CR_HUJAN },
    // rr_komoditi: { required, GC_LYSIMETER_CR_HUJAN },
    // rr_berumput: { required, GC_LYSIMETER_CR_HUJAN },
    name_komoditi: { required },
  },
  props: ["data_input", "t_station", "t_station_id", "t_wmoid", "t_observer", "t_time", "t_date", "t_data_existing", "t_all_data", "t_data_requirement", "t_path", "t_id_form", "t_data_status", "roles", "t_bmkgentry", "d_station"],
  data() {
    return {
      // RC_helper_lycimeter: {...RC_helper},
      showLoading: false,
      s_gundul: "",
      s_komoditi: "",
      s_berumput: "",
      pk_gundul: "",
      pk_komoditi: "",
      pk_berumput: "",
      rr_gundul: "",
      rr_komoditi: "",
      rr_berumput: "",
      eva_gundul: "",
      eva_komoditi: "",
      eva_berumput: "",
      name_komoditi: "",
      cat_lysimeter_gundul: "",
      cat_lysimeter_komoditi: "",
      cat_lysimeter_berumput: "",
      p: 0,

      rc_s_gundul: false,
      rc_s_gundul_message: "",
      rc_s_komoditi: false,
      rc_s_komoditi_message: "",
      rc_s_berumput: false,
      rc_s_berumput_message: "",

      rc_pk_gundul: false,
      rc_pk_gundul_message: "",
      rc_pk_komoditi: false,
      rc_pk_komoditi_message: "",
      rc_pk_berumput: false,
      rc_pk_berumput_message: "",

      rc_rr_gundul: false,
      rc_rr_gundul_message: "",
      rc_rr_komoditi: false,
      rc_rr_komoditi_message: "",
      rc_rr_berumput: false,
      rc_rr_berumput_message: "",

      fdih_status: {
        is_lysimeter: true,
        is_lysimeter_gundul: true,
        is_lysimeter_komoditi: true,
        is_lysimeter_berumput: true,
      },
    };
  },

  mounted() {
    this.initialize();
  },

  methods: {
    initialize() {
      // this.setQCData()
      if (this.t_bmkgentry) {
        this.fdih_status.is_lysimeter_gundul = this.t_bmkgentry.is_lysimeter_gundul;
        this.fdih_status.is_lysimeter_komoditi = this.t_bmkgentry.is_lysimeter_komoditi;
        this.fdih_status.is_lysimeter_berumput = this.t_bmkgentry.is_lysimeter_berumput;

        if (this.fdih_status.is_lysimeter_gundul || this.fdih_status.is_lysimeter_komoditi || this.fdih_status.is_lysimeter_berumput) {
          this.fdih_status.is_lysimeter = true;
        }
      }
    },

    onKeyCheck(event) {
      if (event.key === "ArrowUp" || event.key === "ArrowDown") {
        event.preventDefault();
      }
    },

    //RC 18:00
    checkValidate: function (event, type) {
      console.log("event", event);
      let value = event.target.value;

      switch (type) {
        // case "s_gundul":
        //   var raw = this.RC_helper_lycimeter.check(value, "s_gundul");//RC_FDIH(value, "s_gundul");
        //   var gc = GC_LYSIMETER_SIRAM(value);
        //   // console.log("raw", raw);
        //   // console.log("gc", gc);
        //   this.rc_s_gundul = !raw.status ? (!gc ? false : true) : false;
        //   this.rc_s_gundul_message = raw.message;
        //   break;
        // case "s_komoditi":
        //   var raw = this.RC_helper_lycimeter.check(value, "s_komoditi");//RC_FDIH(value, "s_komoditi");
        //   var gc = GC_LYSIMETER_SIRAM(value);
        //   // console.log("raw", raw);
        //   // console.log("gc", gc);
        //   this.rc_s_komoditi = !raw.status ? (!gc ? false : true) : false;
        //   this.rc_s_komoditi_message = raw.message;
        //   break;
        // case "s_berumput":
        //   var raw = this.RC_helper_lycimeter.check(value, "s_berumput");//RC_FDIH(value, "s_berumput");
        //   var gc = GC_LYSIMETER_SIRAM(value);
        //   // console.log("raw", raw);
        //   // console.log("gc", gc);
        //   this.rc_s_berumput = !raw.status ? (!gc ? false : true) : false;
        //   this.rc_s_berumput_message = raw.message;
        //   break;

        // case "pk_gundul":
        //   var raw = this.RC_helper_lycimeter.check(value, "pk_gundul");//RC_FDIH(value, "pk_gundul");
        //   var gc = GC_LYSIMETER_PERKOLASI(value);
        //   // console.log("raw", raw);
        //   // console.log("gc", gc);
        //   this.rc_pk_gundul = !raw.status ? (!gc ? false : true) : false;
        //   this.rc_pk_gundul_message = raw.message;
        //   break;
        // case "pk_komoditi":
        //   var raw = this.RC_helper_lycimeter.check(value, "pk_komoditi");//RC_FDIH(value, "pk_komoditi");
        //   var gc = GC_LYSIMETER_PERKOLASI(value);
        //   // console.log("raw", raw);
        //   // console.log("gc", gc);
        //   this.rc_pk_komoditi = !raw.status ? (!gc ? false : true) : false;
        //   this.rc_pk_komoditi_message = raw.message;
        //   break;
        // case "pk_berumput":
        //   var raw = this.RC_helper_lycimeter.check(value, "pk_berumput");//RC_FDIH(value, "pk_berumput");
        //   var gc = GC_LYSIMETER_PERKOLASI(value);
        //   // console.log("raw", raw);
        //   // console.log("gc", gc);
        //   this.rc_pk_berumput = !raw.status ? (!gc ? false : true) : false;
        //   this.rc_pk_berumput_message = raw.message;
        //   break;

        // case "rr_gundul":
        //   var raw = this.RC_helper_lycimeter.check(value, "rr_gundul");//RC_FDIH(value, "rr_gundul");
        //   var gc = GC_LYSIMETER_CR_HUJAN(value);
        //   // console.log("raw", raw);
        //   // console.log("gc", gc);
        //   this.rc_rr_gundul = !raw.status ? (!gc ? false : true) : false;
        //   this.rc_rr_gundul_message = raw.message;
        //   break;
        // case "rr_komoditi":
        //   var raw = this.RC_helper_lycimeter.check(value, "rr_komoditi");//RC_FDIH(value, "rr_komoditi");
        //   var gc = GC_LYSIMETER_CR_HUJAN(value);
        //   // console.log("raw", raw);
        //   // console.log("gc", gc);
        //   this.rc_rr_komoditi = !raw.status ? (!gc ? false : true) : false;
        //   this.rc_rr_komoditi_message = raw.message;
        //   break;
        // case "rr_berumput":
        //   var raw = this.RC_helper_lycimeter.check(value, "rr_berumput");//RC_FDIH(value, "rr_berumput");
        //   var gc = GC_LYSIMETER_CR_HUJAN(value);
        //   // console.log("raw", raw);
        //   // console.log("gc", gc);
        //   this.rc_rr_berumput = !raw.status ? (!gc ? false : true) : false;
        //   this.rc_rr_berumput_message = raw.message;
        //   break;

        default:
          break;
      }
    },
    tooltipMessage(attr) {
      return GC_FDIH(attr).message;
    },
    validateTooltipState(name) {
      const { $error } = this.$v[name];
      return false; //$error;
    },
    validateState(item) {
      const { $dirty, $error } = item;
      return $dirty ? !$error : null;
    },
    validationForm() {
      // if (this.fdih_status.is_lysimeter_gundul) {
      //   this.$v.s_gundul.$touch();
      //   this.$v.pk_gundul.$touch();
      //   this.$v.rr_gundul.$touch();

      //   if (this.$v.s_gundul.$anyError || this.$v.pk_gundul.$anyError || this.$v.rr_gundul.$anyError) {
      //     this.$toast({
      //       component: ToastificationContent,
      //       props: {
      //         title: "Data tidak valid. Periksa kembali inputan anda",
      //         icon: "BellIcon",
      //         variant: "danger",
      //       },
      //     });

      //     return;
      //   }
      // }

      // if (this.fdih_status.is_lysimeter_komoditi) {
      //   this.$v.s_komoditi.$touch();
      //   this.$v.pk_komoditi.$touch();
      //   this.$v.rr_komoditi.$touch();
      //   this.$v.name_komoditi.$touch();

      //   if (this.$v.s_komoditi.$anyError || this.$v.pk_komoditi.$anyError || this.$v.rr_komoditi.$anyError || this.$v.name_komoditi.$anyError) {
      //     this.$toast({
      //       component: ToastificationContent,
      //       props: {
      //         title: "Data tidak valid. Periksa kembali inputan anda",
      //         icon: "BellIcon",
      //         variant: "danger",
      //       },
      //     });

      //     return;
      //   }
      // }

      // if (this.fdih_status.is_lysimeter_berumput) {
      //   this.$v.s_berumput.$touch();
      //   this.$v.pk_berumput.$touch();
      //   this.$v.rr_berumput.$touch();

      //   if (this.$v.s_berumput.$anyError || this.$v.pk_berumput.$anyError || this.$v.rr_berumput.$anyError) {
      //     this.$toast({
      //       component: ToastificationContent,
      //       props: {
      //         title: "Data tidak valid. Periksa kembali inputan anda",
      //         icon: "BellIcon",
      //         variant: "danger",
      //       },
      //     });

      //     return;
      //   }
      // }

      // this.$v.$touch()
      this.$v.name_komoditi.$reset();
      let valid = true;
      let messageError = "";

      if (this.fdih_status.is_lysimeter_gundul) {
        this.$v.s_gundul.$touch();
        this.$v.pk_gundul.$touch();
        this.$v.rr_gundul.$touch();

        if (this.$v.s_gundul.$invalid || this.$v.pk_gundul.$invalid || this.$v.rr_gundul.$invalid) {
          valid = false;
          messageError = "Data tidak valid. Periksa kembali inputan anda";
        }
      }

      if (this.fdih_status.is_lysimeter_berumput) {
        this.$v.s_berumput.$touch();
        this.$v.pk_berumput.$touch();
        this.$v.rr_berumput.$touch();

        if (this.$v.s_berumput.$invalid || this.$v.pk_berumput.$invalid || this.$v.rr_berumput.$invalid) {
          valid = false;
          messageError = "Data tidak valid. Periksa kembali inputan anda";
        }
      }

      if (this.fdih_status.is_lysimeter_komoditi) {
        this.$v.s_komoditi.$touch();
        this.$v.pk_komoditi.$touch();
        this.$v.rr_komoditi.$touch();
        this.$v.name_komoditi.$touch();

        if (this.$v.s_komoditi.$invalid || this.$v.pk_komoditi.$invalid || this.$v.rr_komoditi.$invalid || this.$v.name_komoditi.$invalid) {
          valid = false;
          messageError = "Data tidak valid. Periksa kembali inputan anda";
        }
      }

      if (!valid) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: messageError, //"Data tidak valid. Periksa kembali inputan anda",
            icon: "BellIcon",
            variant: "danger",
          },
        });
      } else if (this.fdih_status.is_lysimeter_gundul || this.fdih_status.is_lysimeter_komoditi || this.fdih_status.is_lysimeter_berumput) {
        this.submitFDIH1700();
      }
    },

    format_one_decimal(e) {
      if (Math.round(e * 10) / 10 != e) {
        let text = String(e);
        let filter = text.substring(0, text.length - 1);
        return filter;
      } else {
        if (Math.floor(e) === e) {
          return e;
        } else {
          if (e.toString().indexOf(".") > 0) {
            let count = e.toString().split(".")[1].length || 0;
            return count > 1 ? parseFloat(e).toFixed(1) : e;
          } else {
            return e;
          }
        }
        return e;
      }
    },
    format_two_decimal(e, event) {
      if (Math.round(e * 100) / 100 != e) {
        let text = String(e);
        let filter = text.substring(0, text.length - 1);
        return filter;
      } else {
        if (Math.floor(e) === e) {
          return e;
        } else {
          if (e.toString().indexOf(".") > 0) {
            let count = e.toString().split(".")[1].length || 0;
            return count > 2 ? parseFloat(e).toFixed(2) : e;
          } else {
            return e;
          }
        }

        return e;
      }
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault();
      }
      if (evt.key == "." || evt.key == ",") {
        return evt.preventDefault();
      } else {
        return true;
      }
    },
    cancelForm() {
      this.clearData();
      this.$emit("form", "test");
    },
    clearData() {
      this.s_gundul = "";
      this.s_komoditi = "";
      this.s_berumput = "";
      this.pk_gundul = "";
      this.pk_komoditi = "";
      this.pk_berumput = "";
      this.rr_gundul = "";
      this.rr_komoditi = "";
      this.rr_berumput = "";
      this.eva_gundul = "";
      this.eva_komoditi = "";
      this.eva_berumput = "";
      this.name_komoditi = "";
      this.cat_lysimeter_gundul = "";
      this.cat_lysimeter_komoditi = "";
      this.cat_lysimeter_berumput = "";
      this.p = 0;
      this.$v.$reset();
    },
    submitFDIH1700() {
      // console.log(this.t_station + " - " + this.t_wmoid + " - " + this.t_time + " - " + this.t_date + " - " + this.t_data_status);
      let fdih_id = this.t_id_form; //"1_fdih_" + this.t_date;
      let data_timestamp = this.t_date;
      let post_data = {};

      if (this.t_data_status == "new") {
        post_data = {
          "@type": "Fdih",
          id: fdih_id,
          data_timestamp: data_timestamp,
          observer_id: this.t_observer.id,
          observer_name: this.t_observer.observer,
          // flagdata_lysimeter: 1,
          m_1700ws: {
            s_gundul: this.s_gundul,
            s_komoditi: this.s_komoditi,
            s_berumput: this.s_berumput,
            pk_gundul: this.pk_gundul,
            pk_komoditi: this.pk_komoditi,
            pk_berumput: this.pk_berumput,
            rr_gundul: this.rr_gundul,
            rr_komoditi: this.rr_komoditi,
            rr_berumput: this.rr_berumput,
            eva_gundul: this.eva_gundul,
            eva_komoditi: this.eva_komoditi,
            eva_berumput: this.eva_berumput,
            name_komoditi: this.name_komoditi,
            cat_lysimeter_gundul: this.cat_lysimeter_gundul,
            cat_lysimeter_komoditi: this.cat_lysimeter_komoditi,
            cat_lysimeter_berumput: this.cat_lysimeter_berumput,
            flagm_1700: 0,
          },
        };

        Service.setNewFDIH1700(this.t_path, post_data)
          .then((response) => {
            if (response.status == 201 || response.status == 200 || response.status == 204) {
              this.clearData();
              this.$emit("form", "test");
              this.$swal({
                title: "Input Data Berhasil!",
                text: "Input Data FDIH",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });

              this.showLoading = false;
            }
          })
          .catch((error) => {
            if (error.response.status == 401) {
              this.$swal({
                title: "Gagal proses submit. Unauthorized!",
                text: "Sesi anda telah habis. Logout dan silahkan login kembali",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            } else if (error.response.status == 404) {
              this.$swal({
                title: "Gagal entry!",
                text: "404. Object Not Found",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            } else {
              this.$swal({
                title: "Gagal entry!",
                text: "" + error.response.status,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            }
            this.showLoading = false;
          });
      } else {
        post_data = {
          data_timestamp: data_timestamp,
          // flagdata_lysimeter: 1,
          observer_id: this.t_observer.id,
          observer_name: this.t_observer.observer,
          m_1700ws: {
            s_gundul: this.s_gundul,
            s_komoditi: this.s_komoditi,
            s_berumput: this.s_berumput,
            pk_gundul: this.pk_gundul,
            pk_komoditi: this.pk_komoditi,
            pk_berumput: this.pk_berumput,
            rr_gundul: this.rr_gundul,
            rr_komoditi: this.rr_komoditi,
            rr_berumput: this.rr_berumput,
            eva_gundul: this.eva_gundul,
            eva_komoditi: this.eva_komoditi,
            eva_berumput: this.eva_berumput,
            name_komoditi: this.name_komoditi,
            cat_lysimeter_gundul: this.cat_lysimeter_gundul,
            cat_lysimeter_komoditi: this.cat_lysimeter_komoditi,
            cat_lysimeter_berumput: this.cat_lysimeter_berumput,
            flagm_1700: 1,
          },
        };

        Service.setFDIH07301800(this.t_path + "/" + this.t_id_form, post_data)
          .then((response) => {
            if (response.status == 201 || response.status == 200 || response.status == 204) {
              this.$emit("form", "test");
              let titleMessage = "Input Data Berhasil!";
              let txtMessage = "Input Data FDIH";
              if (this.t_data_status == "update") {
                titleMessage = "Update FDIH 1700 berhasil!";
                txtMessage = "Update FDIH 1700";
              }
              this.$swal({
                title: titleMessage,
                text: txtMessage,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            }
          })
          .catch((error) => {
            let titleMessage = "";
            let txtMessage = "";
            if (error.response.status == 401) {
              txtMessage = "Sesi anda telah habis. Logout dan silahkan login kembali";
              if (this.t_data_status == "update") {
                titleMessage = "Gagal Update. Unauthorized! ";
              } else {
                titleMessage = "Gagal proses submit. Unauthorized!";
              }
              this.$swal({
                title: titleMessage,
                text: txtMessage,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            } else if (error.response.status == 404) {
              txtMessage = "404. Object Not Found";
              if (this.t_data_status == "update") {
                titleMessage = "Gagal Update!";
              } else {
                titleMessage = "Gagal entry!";
              }
              this.$swal({
                title: titleMessage,
                text: txtMessage,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            } else {
              txtMessage = "" + error.response.status;
              if (this.t_data_status == "update") {
                titleMessage = "Gagal Update!";
              } else {
                titleMessage = "Gagal entry!";
              }
              this.$swal({
                title: titleMessage,
                text: txtMessage,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            }
          });
      }
    },

    checkValidateAll() {},
  },
  watch: {
    data_input: {
      deep: true,
      handler() {
        // console.log("data_input: ", this.data_input.i_time);
        if (this.data_input.i_time == "1700ws") {
          this.clearData();
        }
      },
    },

    t_bmkgentry: function (val) {
      this.initialize();
    },

    t_data_existing: function (val) {
      this.s_gundul = val.s_gundul;
      this.s_komoditi = val.s_komoditi;
      this.s_berumput = val.s_berumput;
      this.pk_gundul = val.pk_gundul;
      this.pk_komoditi = val.pk_komoditi;
      this.pk_berumput = val.pk_berumput;
      this.rr_gundul = val.rr_gundul;
      this.rr_komoditi = val.rr_komoditi;
      this.rr_berumput = val.rr_berumput;
      this.eva_gundul = val.eva_gundul;
      this.eva_komoditi = val.eva_komoditi;
      this.eva_berumput = val.eva_berumput;
      this.name_komoditi = val.name_komoditi;
      this.cat_lysimeter_gundul = val.cat_lysimeter_gundul;
      this.cat_lysimeter_komoditi = val.cat_lysimeter_komoditi;
      this.cat_lysimeter_berumput = val.cat_lysimeter_berumput;

      // tambahan dari Pargol
      this.$v.$touch();
      if (!this.fdih_status.is_lysimeter_komoditi) {
        this.$v.name_komoditi.$reset();
      }
    },
    s_gundul: function (val) {
      if (val == 9999) {
        this.eva_gundul = 9999;
      } else {
        this.eva_gundul = Math.round(parseFloat(this.rr_gundul) + parseFloat(val) - this.pk_gundul - this.p).toFixed(1);
      }
    },
    s_komoditi: function (val) {
      if (val == 9999) {
        this.eva_komoditi = 9999;
      } else {
        this.eva_komoditi = Math.round(parseFloat(this.rr_komoditi) + parseFloat(val) - this.pk_komoditi - this.p).toFixed(1);
      }
    },
    s_berumput: function (val) {
      if (val == 9999) {
        this.eva_berumput = 9999;
      } else {
        this.eva_berumput = Math.round(parseFloat(this.rr_berumput) + parseFloat(val) - this.pk_berumput - this.p).toFixed(1);
      }
    },
    pk_gundul: function (val) {
      if (val == 9999) {
        this.eva_gundul = 9999;
      } else {
        this.eva_gundul = Math.round(parseFloat(this.rr_gundul) + parseFloat(this.s_gundul) - val - this.p).toFixed(1);
      }
    },
    pk_komoditi: function (val) {
      if (val == 9999) {
        this.eva_komoditi = 9999;
      } else {
        this.eva_komoditi = Math.round(parseFloat(this.rr_komoditi) + parseFloat(this.s_komoditi) - val - this.p).toFixed(1);
      }
    },
    pk_berumput: function (val) {
      if (val == 9999) {
        this.eva_berumput = 9999;
      } else {
        this.eva_berumput = Math.round(parseFloat(this.rr_berumput) + parseFloat(this.s_berumput) - val - this.p).toFixed(1);
      }
    },
    rr_gundul: function (val) {
      if (val == 9999) {
        this.eva_gundul = 9999;
      } else {
        this.eva_gundul = Math.round(parseFloat(val) + parseFloat(this.s_gundul) - this.pk_gundul - this.p).toFixed(1);
      }
    },
    rr_komoditi: function (val) {
      if (val == 9999) {
        this.eva_komoditi = 9999;
      } else {
        this.eva_komoditi = Math.round(parseFloat(val) + parseFloat(this.s_komoditi) - this.pk_komoditi - this.p).toFixed(1);
      }
    },
    rr_berumput: function (val) {
      if (val == 9999) {
        this.eva_berumput = 9999;
      } else {
        this.eva_berumput = Math.round(parseFloat(val) + parseFloat(this.s_berumput) - this.pk_berumput - this.p).toFixed(1);
      }
    },
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
};
</script>
<style scoped>
.label-entry1 {
  /* text-align: center; */
  color: #3b4253;
  font-weight: 500;
  width: 100%;
  /* border: 2px solid #6e6b7b; */
  border-radius: 3px;
  padding: 4px;
}
.label-entry2 {
  /* text-align: center; */
  color: #3b4253;
  font-weight: 500;
  width: 100%;
  border: 2px solid #6e6b7b;
  border-radius: 3px;
  padding: 4px;
}
#input_s_gundul.rangecheck {
  border-color: #ffeb3b;
  background-color: #fff494 !important;
}
#input_s_komoditi.rangecheck {
  border-color: #ffeb3b;
  background-color: #fff494 !important;
}
#input_s_berumput.rangecheck {
  border-color: #ffeb3b;
  background-color: #fff494 !important;
}
#input_pk_gundul.rangecheck {
  border-color: #ffeb3b;
  background-color: #fff494 !important;
}
#input_pk_komoditi.rangecheck {
  border-color: #ffeb3b;
  background-color: #fff494 !important;
}
#input_pk_berumput.rangecheck {
  border-color: #ffeb3b;
  background-color: #fff494 !important;
}
#input_rr_gundul.rangecheck {
  border-color: #ffeb3b;
  background-color: #fff494 !important;
}
#input_rr_komoditi.rangecheck {
  border-color: #ffeb3b;
  background-color: #fff494 !important;
}
#input_rr_berumput.rangecheck {
  border-color: #ffeb3b;
  background-color: #fff494 !important;
}
.bg-light-primary {
  color: #000000 !important;
}
.bg-light-success {
  color: #000000 !important;
}
</style>
